import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import styles from '../../Css';
import stylesCss from './AnswerCheckBoxStyles';


const AnswerCheckBox = ({ text, selected, enabled, onSelect = () => {}, requiredWarning }) => (
  <View>
    <TouchableOpacity
      enabled={enabled}
      style={[
        ...[stylesCss.questionBox, styles.greyBoxBorder,
          // {if required}
          requiredWarning && styles.inValidColor
        ],
        ...[selected && styles.customTextHighlighter],
      ]}
      onPress={() => enabled && onSelect(!selected)}
    >
      <Text style={[styles.textPrime, selected && styles.fontBold]} allowFontScaling={false}>{text}</Text>
    </TouchableOpacity>
  </View>
);

export default AnswerCheckBox;
