import React, {Component} from 'react';
import {View, TextInput, Image, TouchableOpacity, Platform} from 'react-native';
import styles from '../../Css';
import FieldTitle from './fieldTitle';
import {decimalRegex} from '../../Constant';
import * as FontScaling from '../../components/Text/fontScaling';
import { WEB } from '../../util/platformWindowConfig';


class TextInputPlusMinus extends Component {
  constructor(props) {
    super(props);
    const {value} = props;
    this.state = {
      value: value,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {onChange, max, value} = props;

    if (max && value && parseFloat(max) < parseFloat(state.value)) {
      onChange(max);
      return {
        value: max.toString(),
      };
    } else {
      return {
        value: value || value === 0 ? value.toString() : '',
      };
    }
  }
  onChange = val => {
    const {value} = this.state;
    const {onChange} = this.props;

    if (!isNaN(val) && val.match(decimalRegex)) {
      this.setState({value: val}, () => onChange(val ? val : null));
    } else {
      this.setState({value: value}, () => onChange(parseFloat(value)));
    }
  };
  touchableOnPressMinus = () => {
    //how much should we decrease
    let decreaseBy = 1;
    let {value} = this.state;
    const {onChange, type, units} = this.props;
    if (type === 'ACTIVITY' && units === 'minutes') {
      if (value && parseFloat(value) >= 5) {
        let mod = 0;
        if (value && parseFloat(value)) {
          mod = value % 5;
        }
        value = value ? parseFloat(value) - mod - 5 : 0;
      } else if (!value) {
        value = 0;
      }
    } else if (type === 'ACTIVITY' && units !== 'minutes') {
      if (value && parseFloat(value) >= 1) {
        value = value ? parseFloat(value) - 1 : 0;
      } else if (!value) {
        value = 0;
      }
    } else {
      if (value && parseFloat(value) >= decreaseBy) {
        value = value ? parseFloat(value) - decreaseBy : 0;
      } else if (!value) {
        value = 1;
      }
    }

    this.setState({value: value.toString()}, () => onChange(value));
  };
  touchableOnPressPlus = () => {
    //increase value by
    let increaseBy = 1;
    let {value} = this.state;
    const {onChange, type, max, units} = this.props;
    if (type === 'ACTIVITY' && units === 'minutes') {
      let mod = 0;
      if (value && parseFloat(value)) {
        mod = value % 5;
      }
      value = value ? parseFloat(value) - mod + 5 : 0;
    } else if (type === 'ACTIVITY' && units !== 'minutes') {
      value = value ? parseFloat(value) + 1 : 0;
    } else {
      value = value ? parseFloat(value) + increaseBy : 1;
    }

    if (max && parseFloat(max) < parseFloat(value)) {
      value = max;
    }
    console.log(value);
    this.setState({value: value.toString()}, () => onChange(value));
  };

  render() {
    const {value} = this.state;
    const {
      fieldAlign,
      fieldBg,
      title,
      fieldLt,
      autoFocus,
      getTextInputRef,
    } = this.props;
    return (
      <React.Fragment>
        <View style={[styles.textInputWrapper]}>
          <FieldTitle
            fieldAlign={fieldAlign}
            fieldBg={fieldBg}
            title={title}
            fieldLt={fieldLt}
            darkTxt={this.props.darkTxt}
          />

          <View
            style={[
              styles.textInputField,
              this.props.activitySlider && styles.pickerWidth,
              styles.framePlusMinus,
              styles.flexJcCtAiCt,
              styles.postRelative,
              fieldBg === 'grey'
                ? styles.bgThinGrey
                : fieldBg === 'blue'
                ? styles.bgBlueExLight
                : fieldBg === 'transparent'
                ? styles.bgTransparent
                : styles.bgWhite,
            ]}
            >
            <TouchableOpacity
              onPress={this.touchableOnPressMinus}
              style={[styles.btnActionView, styles.btnActionMinus]}
              disabled={value === 0 || value === '0' || value === null || value === '' ? true : false}>
              <View style={[styles.btnActionFullLeft, 
                (value === 0 || value === '0' || value === null || value === '') && styles.btnBlurState]}>
                <Image
                  style={[
                    Platform.OS === WEB
                      ? styles.plusBtnIcon
                      : styles.plusBtnSmIcon,
                  ]}
                  source={require('../../assets/icons/minus.png')}
                />
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={this.touchableOnPressPlus}
              style={[styles.btnActionView, styles.btnActionPlus]}>
              <View style={[styles.btnActionFullRight]}>
                <Image
                  style={[
                    Platform.OS === WEB
                      ? styles.plusBtnIcon
                      : styles.plusBtnSmIcon,
                  ]}
                  source={require('../../assets/icons/add.png')}
                />
              </View>
            </TouchableOpacity>
            <TextInput
              ref={val => getTextInputRef && getTextInputRef(val)}
              style={[
                styles.framePlusMinusTextBox,
                styles.textAlignCenter,
                styles.framePlusMinusTextBoxSm,
                Platform.OS === WEB
                  ? styles.plusMinuTextWidthSm
                  : styles.plusMinuTextWidthLg,
              ]}
              allowFontScaling={false}
              underlineColorAndroid="transparent"
              // {...this.props}
              value={value ? value.toString() : ''}
              onChangeText={this.onChange}
              keyboardType={Platform.OS === WEB ? 'default' : 'numeric'}
              maxLength={7}
              maxFontSizeMultiplier={FontScaling.fontScaleField}
              autoFocus={autoFocus}
            />
          </View>
        </View>
      </React.Fragment>
    );
  }
}

export default TextInputPlusMinus;
