import React, {Component} from 'react';
import {View, Text, TouchableOpacity, Image, TextInput} from 'react-native';
import PropTypes from 'prop-types';

import {styles, THUMB_SLIDER_CENTER_OFFSET} from './scaleSliderStyles';
import stylesCss from '../../Css';
import LinearGradientCommon from "../LinearGradient/LinearGradient";
import {Slider} from "@miblanchard/react-native-slider";
import {Platform} from "react-native";

const SLIDER_START_COLOR = '#78a8f6';
const SLIDER_END_COLOR = '#082A62';

const MIN_TRACK_TINT_COLOR = 'rgba(255, 255, 255, 0.0)';

const MAX_TRACK_TINT_COLOR = '#E2E2E2';

class ScaleSlider extends Component {
  initialState = {
    currentValue: undefined,
    textInputValue: undefined,
    isSliding: false,
    openKeyboardOnFocus: false,
    allMeasured: false
  };

  constructor(props) {
    super(props);

    this.sliderWrapper = null;
    this.sliderSectionRefs = [];
    this.toolTip = null;
    this.inputReference = React.createRef();

    const {value} = this.props;

    this.state = {
      ...this.initialState,
      initialValue: value,
      currentValue: value,
      textInputValue: ScaleSlider.getTextInputValueFromValue(value)
    }

    this.getScaleDescriptionTooltip = this.getScaleDescriptionTooltip.bind(this);
    this.focusInput = this.focusInput.bind(this);
    this.onPressInput = this.onPressInput.bind(this);
  }

  static getTextInputValueFromValue(value) {
    return !isNaN(value) && value !== null ? '' + value : '';
  }

  static getDerivedStateFromProps(props, state) {
    const {initialValue} = state;
    const {value} = props;

    if (initialValue !== value) {
      return {
        initialValue: value,
        currentValue: value,
        textInputValue: ScaleSlider.getTextInputValueFromValue(value),
      };
    }

    return null;
  }

  onPressInput = () => {
    this.setState({openKeyboardOnFocus: true}, () => {
        this.focusInput();
      });
  };

  focusInput = () => {
    if (this.inputReference.current) {
      this.inputReference.current.focus();
      if (Boolean(this.state.textInputValue)) {
        this.inputReference.current.setNativeProps({
          selection: {
            start: this.state.textInputValue.length,
            end: this.state.textInputValue.length
          }
        });
      }
    }
  };

  blurInput = () => {
    if (this.inputReference.current) {
      this.inputReference.current.blur();
    }
  };

  handleOnSlidingStart = () => {
    this.setState({isSliding: true, openKeyboardOnFocus: false});
    this.focusInput();
  };

  handleOnSlidingComplete = () => {
    this.setState({isSliding: false, openKeyboardOnFocus: false}, () => {
      this.handleOnValueChange(this.state.currentValue, true);
      if (Platform.OS !== "ios") {
        this.focusInput();
      } else {
        // We are having issues with the keyboard popping up on iOS 
        // unless the focus is blurred first. We will blur for now until
        // we find a solution
        this.blurInput();
      }
    });
  };

  handleOnValueChange = (value, finalValue) => {
    const {onUpdate} = this.props;
    const {enabled} = this.props;
    const {initialValue} = this.state;
    value = isNaN(value) ? null : value;
    if (enabled) {
      this.setState({currentValue: value, textInputValue: ScaleSlider.getTextInputValueFromValue(value)});
      if (Boolean(finalValue) && (value !== initialValue)) {
        onUpdate && onUpdate(value);
      }
    }
  }

  handleTextInputOnChange = (evt) => {
    const {nativeEvent} = evt;
    const {text} = nativeEvent;
    this.handleTextInputChange(text);
  }

  handleTextInputChange = (value) => {
    const {maxValue, minValue} = this.props;

    if (value === '') {
      this.handleOnValueChange(null, false);
      return;
    }

    const number = parseInt(value);
    if (!isNaN(number) && number >= minValue && number <= maxValue) {
      this.handleOnValueChange(number, false);
    } else if (isNaN(number)) {
      this.setState({textInputValue: '', currentValue: null});
    }
  }

  handleTextInputEditComplete = () => {
    const {textInputValue} = this.state;
    const number = parseInt(textInputValue);
    this.setState({openKeyboardOnFocus: false});
    this.handleOnValueChange(number, true);
  }

  isSliderScaleEndDescriptionsVisible = () => {
    const {valueDescriptions} = this.props;
    // Only show the slider end descriptions if individual slider values
    // don't have descriptions of their own
    return !Boolean(valueDescriptions);
  }

  isSliderSegmentDescriptionVisible = () => {
    // const {disabledScroll} = this.props;
    const {isSliding} = this.state;

    if (isSliding) {
      this.toolTip = true;
      return true;
    } else {
      return false;
    }
  };

  renderSliderThumb = () => {
    const {enabled} = this.props;
    const {currentValue} = this.state;
    if (!isNaN(currentValue) && currentValue !== null) {
      return <View
        style={[
          ...[styles.sliderIndexWrapper],
          ...[!Boolean(enabled) ? styles.sliderThumbStylingWhenNotEnabled : {}],
          ...[Boolean(enabled) ? styles.sliderThumbStylingWhenEnabled : {}]
        ]}
      >
        {!Boolean(enabled) &&
          <Text style={[...[styles.indexText],]} allowFontScaling={false}>
            {currentValue}
          </Text>}
        {Boolean(enabled) &&
          <View style={[styles.sliderThumbCenterElementWhenEnabled]}>
          </View>}
      </View>
    }
  }

  measureSlider = (e) => {
    this.setState({allMeasured: true});
  };

  render() {
    const {currentValue, textInputValue, allMeasured} = this.state;
    const {
      enabled,
      maxValue,
      minValue,
      scaleStartColor = SLIDER_START_COLOR,
      scaleEndColor = SLIDER_END_COLOR,
      valueDescriptions
    } = this.props;
    this.toolTip = null;
    const sliderSegmentDescriptionVisible = this.isSliderSegmentDescriptionVisible();

    const valueVisibleStyle = {};
    if (!allMeasured) {
      valueVisibleStyle.opacity = 0;
    }

    return (<View>
      <View style={[styles.sliderOurtWrapper]}>
        {enabled && <View style={stylesCss.fieldColSliderData}>
          <View style={stylesCss.sliderDataIconView}>
            <View style={[stylesCss.sliderDataResult, stylesCss.flexAiCt]}>
              <Image
                style={stylesCss.sliderDataIcon}
                source={require("../../assets/icons/tool-tip-data.png")}
              />
              <View style={stylesCss.sliderDataValue}>
                <TouchableOpacity
                  activeOpacity={1}
                  onPress={this.onPressInput}
                  style={{width: "100%"}}
                >
                  <TextInput
                    ref={this.inputReference}
                    style={[stylesCss.textAlignCenter, stylesCss.textPrimeLgBold, {width: "100%"}]}
                    allowFontScaling={false}
                    value={textInputValue ? textInputValue : ''}
                    onBlur={this.handleTextInputEditComplete}
                    onChange={this.handleTextInputOnChange}
                    keyboardType={'numeric'}
                    disabled={!enabled}
                    showSoftInputOnFocus={this.state.openKeyboardOnFocus}
                    onPressIn={this.onPressInput}
                  >
                  </TextInput>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>}
        <View style={[
          ...[styles.sliderBlockWrapper],
        ]}>
          <LinearGradientCommon
            start={{x: 0, y: 0}}
            end={{x: 1, y: 0}}
            colors={[scaleStartColor, scaleEndColor]}
            style={[
              ...[styles.sliderBlockGradient],
              valueVisibleStyle
            ]}>
          </LinearGradientCommon>
          <Slider
            disabled={!enabled}
            trackClickable={currentValue === null || currentValue === undefined} // To help prevent slider values changing during a scroll, only let the slider be clickable when there is no value
            value={currentValue}
            onValueChange={(value) => this.handleOnValueChange(value[0], !this.state.isSliding)}
            onSlidingStart={this.handleOnSlidingStart}
            onSlidingComplete={this.handleOnSlidingComplete}
            minimumValue={minValue}
            maximumValue={maxValue}
            minimumTrackTintColor={MIN_TRACK_TINT_COLOR}
            maximumTrackTintColor={MAX_TRACK_TINT_COLOR}
            containerStyle={styles.sliderContainerStyle}
            renderTrackEndMarkers={true}
            endScaleMarkerStyle={[stylesCss.textPrimeBold, styles.sliderScaleEndMarker]}
            trackStyle={[styles.sliderTrackStyling, stylesCss.textPrimeBold]}
            step={1}
            renderThumbComponent={() => this.renderSliderThumb()}
            renderAboveThumbComponent={(i) => {
              return sliderSegmentDescriptionVisible && Boolean(valueDescriptions) && Boolean(valueDescriptions[currentValue])
                ? <View style={[{left: THUMB_SLIDER_CENTER_OFFSET}]}>
                  {this.getScaleDescriptionTooltip(this.getBubblePosition(currentValue, minValue, maxValue), {}, valueDescriptions[currentValue])}
                </View>
                : null;
            }}
            layoutComplete={() => this.measureSlider()}
          >
          </Slider>
          {
            /**
             * Tooltip at 0
             */
            sliderSegmentDescriptionVisible && this.isSliderScaleEndDescriptionsVisible() &&
            <View style={[{position: 'absolute', left: 0, bottom: 0}]}>{this.getMinScaleDescriptionTooltip()}</View>
          }
          {
            /**
             * Tooltip at max
             */
            sliderSegmentDescriptionVisible && this.isSliderScaleEndDescriptionsVisible() &&
            <View style={[{position: 'absolute', right: 0, bottom: 0}]}>{this.getMaxScaleDescriptionTooltip()}</View>
          }
        </View>
      </View>
    </View>);
  };

  getBubblePosition(currentValue, minValue, maxValue) {
    const distanceFromMin = currentValue - minValue;
    const spanOfSlider = maxValue - minValue;

    if (distanceFromMin / spanOfSlider <= 0.2) {
      return {
        left: -THUMB_SLIDER_CENTER_OFFSET
      };
    } else if (distanceFromMin / spanOfSlider >= 0.8) {
      return {
        right: -THUMB_SLIDER_CENTER_OFFSET
      };
    }

    return {
      left: 0,
      right: 0
    };
  }

  getScaleDescriptionTooltip(bubblePosition, arrowPosition, description) {
    return <View style={[styles.tooltipWrapper]}>
      <View
        style={[styles.tooltipBubblePosition, bubblePosition]}>
        <View style={[styles.tooltipTextWrapper]}><Text
          style={[stylesCss.textPrime, stylesCss.textAlignCenter]}
          allowFontScaling={false}>{description}</Text></View>
      </View>
      <Image
        style={[styles.arrowHead, arrowPosition]}
        source={require('../../assets/icons/polygon.png')}
      />
    </View>;
  }

  getMinScaleDescriptionTooltip() {
    return this.getScaleDescriptionTooltip({left: 0}, {left: THUMB_SLIDER_CENTER_OFFSET}, this.props.minScaleDescription);
  }

  getMaxScaleDescriptionTooltip() {
    return this.getScaleDescriptionTooltip({right: 0}, {right: THUMB_SLIDER_CENTER_OFFSET}, this.props.maxScaleDescription);
  }
};

ScaleSlider.defaultProps = {
  selectedIndex: -1, enabled: true, minScaleDescription: 'None', maxScaleDescription: 'Highest possible',
};

ScaleSlider.propTypes = {
  value: PropTypes.number,
  enabled: PropTypes.bool,
  onUpdate: PropTypes.func,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  minScaleDescription: PropTypes.string,
  maxScaleDescription: PropTypes.string,
  /**
   * If this value is set, the min/max scale won't be displayed and
   * the slider will display a tooltip with the description of that value
   */
  valueDescriptions: PropTypes.arrayOf(PropTypes.string),
  scaleStartColor: PropTypes.string,
  scaleEndColor: PropTypes.string,
  /**
   * This is important for iOS device to avoid the bounce effect.
   * In iOS devices, scroll event should disabled when user starts interacting.
   */
  disabledScroll: PropTypes.func,
};

export default ScaleSlider;
