import React, { Component, Fragment } from 'react';
import { View, TouchableOpacity, Text, Keyboard, Platform } from 'react-native';
import stylesResponsive from '../../CssResponsive';
import styles from '../../Css';
import Header from '../../components/Header/header';
import Tabs from '../../components/Tab/Tab';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import Attachments from '../../components/Attachment/Attachments';
import JournalTemplateHeader from '../../components/RoutineTemplate/JournalEntryHeader/Edit';
import KeyboardAwareScrollViewHoc from '../../components/KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc';
import LabReportDetails from '../../components/RoutineTemplate/LabReportDetails';
import Medications from '../../components/RoutineTemplate/Medications/Edit';
import Activity from '../../components/RoutineTemplate/Activity/Edit';
import Symptoms from '../../components/RoutineTemplate/Symptoms/Edit';
import HealthData from '../../components/RoutineTemplate/HealthData/Edit';
import Notes from '../../components/RoutineTemplate/Notes/Edit';
import Questions from '../../components/RoutineTemplate/Questions/Edit';
import ActivityIndicatorCustom from '../../components/ActivityIndicator/activityIndicator';
import * as comTitles from './titles';
import { hasValue } from '../../util/hashValueGenerator';
import EmptyPopup from '../../components/ConfirmationBox/JournalEmptyPopup';
import SpaceBarBottom from '../../components/SpaceBar/spaceBarBottom';
import * as FieldDetails from '../../components/TextInput/fieldDetails';
import { JOURNALS, DASHBOARD, MEDICATIONS } from '../../navigation/path';
import { scrollEvent } from './ScrollEventRegister';
import PageContainer from '../../components/PageLayout/PageContainer';
import StorageBanner from '../../components/Banner/StorageBanner';
import ButtonPopupMenu from '../../components/PopupMenu/buttonPopupMenu';
import ButtonAttachmentPopupMenu from '../../components/PopupMenu/buttonAttachmentPopupMenu';
import _ from 'lodash';
import AddAttachment from '../../components/Button/addAttachment';
import { journalSaveOptions } from '../../Constant/index';
import SavingConfirmation from '../../components/ConfirmationBox/savingConfirmation';
import { alertBody, journalTitle } from '../../GlobalFunctions/GlobalString';
import Cropper from '../../components/ImageCropper/AmazingCropperPage';
import { handleCommonBackNavigation } from '../../navigation/RootNavigation';
import MenuOverlayContainer from '../../components/PageLayout/MenuOverlayContainer';
import WarningNotes from '../../components/Warning/warning';
import { pendoTrackEvent } from '../../util/pendoConfig';
import CancelButton from '../../components/Button/cancelButton';
import { getDayDifference, getEntryDateTime } from '../../util/commonUiLogic';
import { APP_FEATURE_DASHBOARD_SCHEDULE_ENABLED } from '../../util/featureConfig';
import { TAB_BREAk_MAX_WIDTH, TAB_BREAk_POINT_END, WEB } from '../../util/platformWindowConfig';
let initialDate = null;

class AddRoutineDetails extends Component {
  constructor(props) {
    super(props);
    if (this.props.newJournal) {
      const {
        newJournal = {},
        journalEntryTypesProcessed,
        isNew,
        windowHeight,
        windowWidth,
        applicationSettingsDigest,
        temporyEntryObject
      } = this.props;
      this.dataRef = temporyEntryObject; // dataRef = &temporyEntryObject
      const { newJournalEntryTypeId } = applicationSettingsDigest;
      const {
        entryDate,
        entryType,
        title,
        artifactId,
        healthProvider,
        labReportType,
        symptoms,
        metrics,
        entry,
        description,
        flagged,
        deviceName,
      } = _.cloneDeep(newJournal);
      //cloning is essential, otherwise whatever change you do will reflect on redux store cuz of referrence
      const {index = 0, routes = []} = (props.navigation?.getState?.() || {})
      const resizeFlag = routes?.[index]?.params?.fromWindowResize
      if (!resizeFlag) {
        this.dataRef.artifactId = artifactId;
        this.dataRef.entryDate = entryDate;
        this.dataRef.journalEntryTypes = journalEntryTypesProcessed[entryType];
        this.dataRef.dosagesMedication = newJournal.dosagesTemp
          ? newJournal.dosagesTemp
          : newJournal.dosages
            ? actions.getSortedMedicationDosagesWithTreatmentObject(newJournal.dosages, false, props.treatmentsProcessed)
            : [];
        this.dataRef.dosagesActivity = newJournal.dosagesActTemp
          ? newJournal.dosagesActTemp
          : newJournal.dosages
            ? actions.getSortedActivityDosagesWithTreatmentObject(newJournal.dosages, false, props.treatmentsProcessed)
            : [];
        this.dataRef.title =
          newJournalEntryTypeId === this.dataRef.journalEntryTypes?.id && isNew
            ? ''
            : title;
        this.dataRef.healthProvider = healthProvider;
        this.dataRef.healthProviderName = null;
        this.dataRef.labReportType = labReportType;
        this.dataRef.symptoms = symptoms ? actions.getSortedSymptomsWithSymptomObject(symptoms, false, props.symptomsDigestProcessed) : [];
        this.dataRef.deletedSymptomIds = [];
        this.dataRef.metrics = metrics ? actions.getSortedMetricsWithSymptomObject(metrics, false, props.metricsDigestProcessed) : [];
        this.dataRef.entry = entry;
        this.dataRef.description = description;
        this.dataRef.entryType = entryType;
        this.dataRef.flagged = flagged;
        this.dataRef.hasValue = this.getJournalHashValue();
      }
      this.reminderDateTime = null;
      if (props.route?.params && props.route?.params?.reminderDateTime) {
        this.reminderDateTime = props.route?.params?.reminderDateTime;
      }
      let fromPopup = null;
      if (props.route?.params && props.route?.params.fromPopup) {
        fromPopup = props.route?.params.fromPopup;
      }
      if (this.props.activeTab !== 0) {
        this.props.setActiveTab(0);
      }
      this.state = {
        saveWait: false,
        isNew: isNew,
        saveOrDoNotSaveConfirm: false,
        copyPreviousEntry: false,
        copyPreviousSymptoms: false,
        copyPreviousMetrics: false,
        copyPreviousMedication: false,
        copyPreviousActivity: false,
        deleteWait: false,
        emptyConfirm: false,
        windowHeight: windowHeight,
        windowWidth: windowWidth,
        validation: true,
        isEmptyJournal: true,
        isScrollDisabled: true,
        fromPopup: fromPopup,
        loadWait: false,
        opt: false,
        deviceJournal: deviceName ? true : false,
        healthdataErrors: null,
        unMounted: false,
        contactSaved: false
      };
      this.scrollViewRef = null;
      this.savingAttempts = 0;
    } else {
      this.dataRef = {};
      this.props.navigation.replace(JOURNALS.path);
    }
  }

  componentDidMount() {
    this.props.navigation.setParams({...(this.props?.route?.params || {}), fromWindowResize: false });
    this.setState({ loadWait: false })
    initialDate = this.dataRef?.entryDate;
    const { getRef, newJournal, showMobileMenu, navigation, hideMobileMenu, pullSchedulars, journalUserId } = this.props;
    if (newJournal) {
      this.updateIsEmptyJournal();
    }
    getRef && this.props.getRef(this);
    navigation.addListener('focus', () => {
      if (Boolean(APP_FEATURE_DASHBOARD_SCHEDULE_ENABLED)) {
        pullSchedulars(journalUserId);
      }  
      hideMobileMenu();
      pendoTrackEvent('Journals/@AddEditJournal');
    });
    navigation.addListener('blur', () => {
      showMobileMenu();
    });
  }

  static getDerivedStateFromProps(props, state) {
    const { route, navigation, windowWidth, fromMain, temporyEntryObject } = props;
    props.hideMobileMenu();
    if (
      // route?.params &&
      // route?.params.from &&
      // route?.params.from === JOURNALS.path &&
      windowWidth &&
      windowWidth > TAB_BREAk_MAX_WIDTH &&
      !fromMain &&
      !state?.unMounted
    ) {
      AddRoutineDetails.eliminateRoutesAndNavigate(
        navigation,
        {
          from: route.name,
          journalPath: JOURNALS.addJournalPathSuffix,
          journalName: temporyEntryObject?.title,
          fromWindowResize: true,
        },
        [JOURNALS.path, JOURNALS.addJournalPath],
        JOURNALS.path,
      );
      return {
        unMounted: true,
      }
      // navigation.navigate(
      //   JOURNALS.path, {
      //   from: route.name,
      //   journalPath: JOURNALS.addJournalPathSuffix,
      //   journalName: this.title,
      //   fromWindowResize : true
      // }
      // );
    } else {
      return null;
    }
    // props.hideMobileMenu();
  }

  static eliminateRoutesAndNavigate = (navigation, params = {}, match = [], path) => {
    const { routes = [] } = navigation.getState();
    const newRoutes = [...routes.filter(item => !match.includes(item.name)), {
      name: path,
      params: {
        ...params
      }
    }]
    navigation.reset({
      index: newRoutes.length - 1,
      routes: newRoutes,
    });
  }

  getJournalHashValue = () => {
    return hasValue(
      this.dataRef?.entryDate +
      ',' +
      this.periodic +
      ',' +
      this.dataRef?.title +
      ',' +
      JSON.stringify(this.dataRef?.dosagesMedication) +
      ',' +
      JSON.stringify(this.dataRef?.dosagesActivity) +
      ',' +
      this.dataRef?.healthProvider +
      ',' +
      this.dataRef?.labReportType +
      ',' +
      JSON.stringify(this.dataRef?.symptoms) +
      ',' +
      JSON.stringify(this.dataRef?.metrics) +
      ',' +
      this.dataRef?.entry +
      ',' +
      this.dataRef?.description,
    );
  }

  updateIsEmptyJournal = () => {
    //let title = true;
    let entryEmpty = true;
    // if (this.title !== '') {
    //   title = false;
    // }
    const metricsFilter = this.dataRef?.metrics?.filter(
      res =>
        (res.userMetricLog?.value &&
          res.userMetricLog?.value !== '' &&
          !isNaN(res.userMetricLog?.value)) ||
        (res.value && res.value !== '' && !isNaN(res.value)),
    );

    const symptomsFilter = this.dataRef?.symptoms?.filter(res => res.symptom ? res.severity !== null : res.userSymptomLog && res.userSymptomLog?.severity !== null);

    const dosagesMedicationFilter = this.dataRef?.dosagesMedication?.filter(
      res =>
        (res && res.hasOwnProperty('quantity') &&
          res.quantity !== '' &&
          res.quantity !== null) || (res && res.dosages && res.dosages[0] && res.dosages[0].quantity !== '' && res.dosages[0].quantity !== null)
      // &&
      //res.quantity !== 0 &&
      // res.accept,
    );


    const dosagesActivityFilter = this.dataRef?.dosagesActivity?.filter(
      res => (res && res.hasOwnProperty('quantity') &&
        res.quantity !== '' &&
        res.quantity !== null) || (res && res.dosages && res.dosages[0] && res.dosages[0].quantity !== '' && res.dosages[0].quantity !== null)
    );
    if (this.dataRef?.entry && this.dataRef?.entry?.trim() !== '') {
      entryEmpty = false;
    }

    const metricsEmpty = !(metricsFilter && metricsFilter.length > 0);
    const symptomsEmpty = !(symptomsFilter && symptomsFilter.length > 0);
    const dosagesMedicationEmpty = !(
      dosagesMedicationFilter && dosagesMedicationFilter.length > 0
    );
    const dosagesActivityEmpty = !(
      dosagesActivityFilter && dosagesActivityFilter.length > 0
    );

    this.setState({
      isEmptyJournal:
        // title &&
        entryEmpty &&
        metricsEmpty &&
        symptomsEmpty &&
        dosagesMedicationEmpty &&
        dosagesActivityEmpty,
    });
  };

  updateEntryDate = date => {
    this.dataRef.entryDate = date;
  };

  updateTitle = title => {
    this.dataRef.title = title;
    this.updateIsEmptyJournal();
  };

  updateDescription = description => {
    this.dataRef.description = description;
  };

  updateHealthProvider = (id , name)=> {
    this.dataRef.healthProvider = id;
    this.dataRef.healthProviderName = name;
  };

  updateLabReportType = id => {
    this.dataRef.labReportType = id;
  };

  updateHealthData = metrics => {
    this.dataRef.metrics = metrics;
    this.updateIsEmptyJournal();
  };

  updateSymptoms = (symptoms, deletedSymptomId) => {
    this.dataRef.symptoms = symptoms;
    if (deletedSymptomId) this.dataRef.deletedSymptomIds.push(deletedSymptomId);
    this.updateIsEmptyJournal();
  };

  updateMedication = dosagesMedication => {
    this.dataRef.dosagesMedication = dosagesMedication;
    this.updateIsEmptyJournal();
  };

  updateActivity = dosagesActivity => {
    this.dataRef.dosagesActivity = dosagesActivity;
    this.updateIsEmptyJournal();
  };

  updateEntry = entry => {
    this.dataRef.entry = entry;
    this.updateIsEmptyJournal();
  };

  journalHasAttachments = artifactId => {
    const { attachmentsCompleteDigest } = this.props;
    let attachments = [];
    if (attachmentsCompleteDigest) {
      attachments = attachmentsCompleteDigest.userArtifactAttachments.filter(
        function (attachment) {
          return attachment.userArtifact === artifactId;
        },
      );
    }
    return attachments.length > 0;
  };

  saveOperation = () => {
    const {
      newJournal = {},
      metricsDigestProcessed,
      symptomsDigestProcessed,
      treatmentsProcessed,
      temporyQuestionObject,
      onSaveHealthQuestion,
      updateTemporyQuestionObject,
      journalUserId,
    } = this.props;
    const { validation, discardEmpty, opt } = this.state;

    let symptomsFilter = this.dataRef.symptoms;
    let symptomsFilterArr = [];
    let emptySymptomNames = [];

    let metricsFilter = this.dataRef.metrics;
    let metricsFilterArr = [];
    let emptyMetricsNames = [];

    let dosagesFilterMedication = this.dataRef.dosagesMedication;
    let dosagesFilterActivity = this.dataRef.dosagesActivity;
    let dosagesFilterArr = [];

    let emptyDosagesNames = [];
    let newTreatments = [];
    let newSymptoms = [];
    let newMetrics = [];
    const { selectableMetrics } = metricsDigestProcessed;
    const { userSymptoms } = symptomsDigestProcessed;

    this.setState({ saveWait: true });

    if (symptomsFilter.length !== 0) {
      symptomsFilter.forEach((res, index) => {
        if (res.symptom) {
          let temp = {
            id: res.id !== -1 ? res.id : -index,
            createdOn: res.createdOn,
            modifiedOn: res.modifiedOn,
            artifactId: res.artifactId,
            symptom: res.symptom,
            severity: res.severity,
            startTime: res.startTime,
            endTime: res.endTime,
            userArtifactType: res.userArtifactType,
          };
          if (userSymptoms[res.symptom]) {
            if (res.severity !== null) {
              symptomsFilterArr.push(temp);
            } else {
              emptySymptomNames.push(userSymptoms[res.symptom].name);
            }
          }
        } else {
          if (res.userSymptomLog && res.userSymptomLog.severity === null) {
            emptySymptomNames.push(res.name);
          } else {
            let tempRes = {
              ...res,
              id: -(index + 100),
              userSymptomLog: { ...res.userSymptomLog, id: -(index + 1000) },
            };
            newSymptoms.push(tempRes);
          }
        }
      });
    }
    if (metricsFilter.length !== 0) {
      metricsFilter.forEach((res, index) => {
        if (res.metric) {
          let temp = {
            id: res.id !== -1 ? res.id : -index,
            createdOn: res.createdOn,
            modifiedOn: res.modifiedOn,
            artifactId: res.artifactId,
            metric: res.metric,
            value: res.value,
            unitOfMeasure: res.unitOfMeasure,
            isActive: res.isActive,
            userArtifactType: res.userArtifactType,
            active: res.active,
            uomOption: res.uomOption,
            valueType: res.uomOption ? "OPTION" : "DEFAULT",
          };

          if (selectableMetrics[res.metric]) {
            if (res.value === '' || res.value === null || isNaN(res.value)) {
              emptyMetricsNames.push(selectableMetrics[res.metric].name);
            } else {
              metricsFilterArr.push(temp);
            }
          }
        } else {
          if (res.userMetricLog && res.userMetricLog.value === null) {
            emptyMetricsNames.push(res.name);
          } else {
            let tempRes = {
              ...res,
              id: -(index + 100),
              userMetricLog: { ...res.userMetricLog, id: -(index + 1000) },
            };
            newMetrics.push(tempRes);
          }
        }
      });
    }
    if (dosagesFilterMedication.length !== 0) {
      dosagesFilterMedication.forEach((res, index) => {

        if (res.new) delete res.new;
        if (res.updated) delete res.updated;

        if (res.treatment && !res.edited) {
          let temp = {
            id: res.id !== -1 ? res.id : -(dosagesFilterArr.length + 1),
            createdOn: res.createdOn,
            modifiedOn: res.modifiedOn,
            artifactId: res.artifactId,
            quantity: res.quantity,
            units: res.units,
            assigned: res.assigned,
            treatment: res.treatment,
            userArtifactType: res.userArtifactType,
          };
          if (treatmentsProcessed[res.treatment]) {
            if (res.quantity === '' || res.quantity === null || !res.accept) {
              // || parseFloat(res.quantity) === 0 remove for fixed CR-1538
              emptyDosagesNames.push(treatmentsProcessed[res.treatment].name);
            } else if (res.accept) {
              dosagesFilterArr.push(temp);
            }
          }
        } else if (res.edited && res.treatment) {
          if (treatmentsProcessed[res.treatment]) {
            if (res.quantity === '' || res.quantity === null) {
              emptyDosagesNames.push(treatmentsProcessed[res.treatment].name);
            } else {
              let treatmentObj = treatmentsProcessed[res.treatment];
              treatmentObj = {
                ...treatmentObj,
                dosages: [
                  {
                    ...treatmentObj.dosages[Object.keys(treatmentObj.dosages)[0]],
                    units: res.unit ? res.unit : res.units,
                    quantity: res.quantity,
                  },
                ],
                userDosingLog: {
                  units: res.unit ? res.unit : res.units,
                  quantity: res.quantity,
                  id: -(index + 1000)
                }
              };
              newTreatments.push(treatmentObj);
            }
          }
        } else {
          if (
            res.dosages &&
            res.dosages[Object.keys(res.dosages)[0]] &&
            (res.dosages[Object.keys(res.dosages)[0]].quantity || res.dosages[Object.keys(res.dosages)[0]].quantity === 0)
          ) {
            if (
              res.dosages[Object.keys(res.dosages)[0]].quantity === '' ||
              res.dosages[Object.keys(res.dosages)[0]].quantity === null ||
              !res.accept
            ) {
              // || parseFloat(res.quantity) === 0 remove for fixed CR-1538
              emptyDosagesNames.push(res.name);
            } else if (res.accept) {
              let tempRes = {
                ...res,
                id: -(index + 100),
                userDosingLog: { ...res.userDosingLog, id: -(index + 1000) },
              };
              newTreatments.push(tempRes);
            }
          }
        }
      });
    }
    if (dosagesFilterActivity.length !== 0) {
      dosagesFilterActivity.forEach((res, index) => {
        if (res.new) delete res.new;
        if (res.updated) delete res.updated;

        if (res.treatment && !res.edited) {
          let temp = {
            id: res.id !== -1 ? res.id : -(dosagesFilterArr.length + 1),
            createdOn: res.createdOn,
            modifiedOn: res.modifiedOn,
            artifactId: res.artifactId,
            quantity: res.quantity,
            units: res.units,
            assigned: res.assigned,
            treatment: res.treatment,
            userArtifactType: res.userArtifactType,
          };
          if (treatmentsProcessed[res.treatment]) {
            if (res.quantity === '' || res.quantity === null || !res.accept) {
              // || parseFloat(res.quantity) === 0 remove for fixed CR-1538
              emptyDosagesNames.push(treatmentsProcessed[res.treatment].name);
            } else if (res.accept) {
              dosagesFilterArr.push(temp);
            }
          }
        } else if (res.edited && res.treatment) {
          if (treatmentsProcessed[res.treatment]) {
            if (res.quantity === '' || res.quantity === null) {
              emptyDosagesNames.push(treatmentsProcessed[res.treatment].name);
            } else {
              let treatmentObj = treatmentsProcessed[res.treatment];
              treatmentObj = {
                ...treatmentObj,
                dosages: [
                  {
                    ...treatmentObj.dosages[Object.keys(treatmentObj.dosages)[0]],
                    units: res.unit ? res.unit : res.units,
                    quantity: res.quantity,
                  },
                ],
                userDosingLog: {
                  units: res.unit ? res.unit : res.units,
                  quantity: res.quantity,
                  id: -(index + 10000)
                }
              };
              newTreatments.push(treatmentObj);
            }
          }
        } else {
          if (
            res.dosages &&
            res.dosages[Object.keys(res.dosages)[0]] &&
            (res.dosages[Object.keys(res.dosages)[0]].quantity || res.dosages[Object.keys(res.dosages)[0]].quantity === 0)
          ) {
            if (
              res.dosages[Object.keys(res.dosages)[0]].quantity === '' ||
              res.dosages[Object.keys(res.dosages)[0]].quantity === null ||
              !res.accept
            ) {
              // || parseFloat(res.quantity) === 0 remove for fixed CR-1538
              emptyDosagesNames.push(res.name);
            } else if (res.accept) {
              let tempRes = {
                ...res,
                id: -(index + 100),
                userDosingLog: { ...res.userDosingLog, id: -(index + 10000) },
              };
              newTreatments.push(tempRes);
            }
          }
        }
      });
    }

    let JournalEntryObject = {
      newTreatments: newTreatments,
      newSymptoms: newSymptoms,
      newMetrics: newMetrics,
      journalEntry: {
        id: newJournal.id,
        createdOn: newJournal.createdOn,
        modifiedOn: newJournal.modifiedOn,
        artifactId: newJournal.artifactId,
        entry: this.dataRef.entry,
        entryDate: this.dataRef.entryDate,
        endDate: newJournal.endDate,
        entryType: newJournal.entryType,
        title: this.dataRef.title,
        description: this.dataRef.description,
        flagged: this.dataRef.flagged,
        healthProvider: this.dataRef.healthProvider,
        appointmentType: null,
        labReportType: this.dataRef.labReportType,
        draft: false,
        surveys: [],
        symptoms: symptomsFilterArr,
        metrics: metricsFilterArr,
        dosages: dosagesFilterArr,
        userArtifactType: newJournal.userArtifactType,
        createdByNotification: Boolean(this.reminderDateTime) || Boolean(newJournal?.createdByNotification),
        reminderDateTime: Boolean(this.reminderDateTime) ? this.reminderDateTime : Boolean(newJournal?.reminderDateTime) ? newJournal?.reminderDateTime : getEntryDateTime(new Date().getHours() + ':' + new Date().getMinutes()),
      },
      requestType: opt ? opt : journalSaveOptions.save,
    };

    const errors = metricsFilter.map(item => !item?.error || item?.metric ? false : true);
    if ((this.dataRef.title && this.dataRef.title.trim() === '') || !this.dataRef.title) {
      this.setState({ validation: !validation, saveOrDoNotSaveConfirm: false, saveWait: false });
      if (this.scrollViewRef) {
        setTimeout(() => {
          Platform.OS === WEB
            ? this.scrollViewRef?.scrollTo({ x: 0, y: 0, animated: true })
            : this.scrollViewRef?.scrollToPosition(0, 0, true);
        }, 500);
      }
    } else if (errors.filter(item => item).length > 0) {
      this.setState({ healthdataErrors: errors, saveWait: false });
    } else if (discardEmpty) {
      this.saveJournalEntry(JournalEntryObject, newMetrics);
    } else if (
      emptyDosagesNames.length !== 0 ||
      emptyMetricsNames.length !== 0 ||
      emptySymptomNames.length !== 0
    ) {
      this.setState({
        saveWait: false,
        saveOrDoNotSaveConfirm: false,
        emptyConfirm: true,
        isNew: false,
        emptyDosagesNames,
        emptyMetricsNames,
        emptySymptomNames,
      });
    } else {
      if (temporyQuestionObject) {
        this.setState({ saveWait: true }, () => {
          onSaveHealthQuestion(
            temporyQuestionObject,
            temporyQuestionObject.artifactId,
            () => {
              updateTemporyQuestionObject(null);
              this.saveJournalEntry(JournalEntryObject, newMetrics);
            },
            journalUserId,
          );
        });
      } else {
        this.saveJournalEntry(JournalEntryObject, newMetrics);
      }
    }
  }

  sendThreadToSleep = (ms = 100) => {
    return new Promise(r => setTimeout(r, ms));
  }

  handleOnSubmitSave = async () => {
    Keyboard.dismiss();
    const { newJournal, navigation, commonErrorHandler } = this.props;
    this.setState({ saveWait: true });
    if (!newJournal && this.savingAttempts < 5) {
      this.savingAttempts += 1;
      this.setState({ saveWait: true });
      this.forceUpdate();
      await this.sendThreadToSleep(100);
      this.handleOnSubmitSave();
    } else if (newJournal) {
      await this.sendThreadToSleep(300);
      this.saveOperation();
    } else {
      commonErrorHandler("Saving attempts exceeded. So aborting the operation!");
      this.setState({ saveWait: false });
      navigation.replace(JOURNALS.path);
    }
  };

  addContactAndUpdateEntry = (journalEntryObject, healthProviderName, newMetrics) => {
    const { updateContact, contacts, journalUserId } = this.props;
    let addNewContact = {
      addNewContact: {
        contact: {
          id: -1,
          givenName: healthProviderName,
          isCareTeam: true,
        },
      },
    };
    updateContact(
      addNewContact,
      contacts,
      (success, contactData) => {
        if (success) {
          this.setState({contactSaved : true})
          journalEntryObject.journalEntry.healthProvider = contactData.id;
        }

        this.saveJournalEntry(journalEntryObject, newMetrics);
      },
      journalUserId,
      false,
    );
  };

  saveJournalEntry = (JournalEntryObject, newMetrics) => {
    const {
      journalUserId,
      onSaveJournalEntry,
      finishedSchedules = [],
      getOverdueAddhocEntry,
      applicationSettingsDigest
    } = this.props;
    const { labsEntryTypeId } = applicationSettingsDigest;
    const { contactSaved } = this.state;

    if (Boolean(labsEntryTypeId === this.dataRef.journalEntryTypes?.id) &&
      !Boolean(this.dataRef.healthProvider) &&
      Boolean(this.dataRef.healthProviderName) &&
      !Boolean(contactSaved)) {
        this.addContactAndUpdateEntry(JournalEntryObject, this.dataRef.healthProviderName, newMetrics);
        return;
    }
    // find the overdue completed entry
    const dayDiff = Boolean(APP_FEATURE_DASHBOARD_SCHEDULE_ENABLED) && getDayDifference(Boolean(this.reminderDateTime) ? this.reminderDateTime : JournalEntryObject?.journalEntry?.entryDate);
    let finishedScheduleList = finishedSchedules;
    // overdue routines navigate from reminders
    if (Boolean(dayDiff > 0) && Boolean(this.reminderDateTime)) {
      finishedScheduleList = this.filterCompletedOverdueScheduleList(JournalEntryObject?.journalEntry?.entryType, dayDiff, this.reminderDateTime);
    }
    let isFromOverdueAddhocEntry = false;
    if (Boolean(APP_FEATURE_DASHBOARD_SCHEDULE_ENABLED) && Boolean(dayDiff > 0) && !Boolean(JournalEntryObject?.journalEntry?.createdByNotification)) {
      isFromOverdueAddhocEntry = true;
    }
    this.setState({ saveWait: true }, () => {
      onSaveJournalEntry(
        JournalEntryObject,
        (success, data) => {
          if (success) {
            if (isFromOverdueAddhocEntry) {
              getOverdueAddhocEntry(
                data.journalEntry?.id,
                data.journalEntry?.entryType,
                dayDiff,
                journalUserId,
                (succes, res) => {
                  if (succes && Boolean(res?.reminderDateTime)) {
                    finishedScheduleList = this.filterCompletedOverdueScheduleList(data.journalEntry?.entryType, dayDiff, res?.reminderDateTime);
                  } 
                  this.saveJournalEntrySuccess(data, newMetrics, JournalEntryObject.requestType, finishedScheduleList);
                }
              )
            } else {
              this.saveJournalEntrySuccess(data, newMetrics, JournalEntryObject.requestType, finishedScheduleList);
            }
          } else {
            this.setState({
              saveWait: false,
              emptyConfirm: false,
              isNew: false,
            });
          }
        },
        journalUserId,
        this.deletedSymptomIds,
        false,
        isFromOverdueAddhocEntry
      );
    });
  };

  saveJournalEntrySuccess = (data, newMetrics, requestType, finishedScheduleList) => {
    const {
      navigation,
      updateTreatments,
      updateUserSymptom,
      updateUserMetrics,
      updateUserMetricUOM,
      updateDashboardGraphSuccess,
      onSelectGraphItem,
      onHideShowGraph,
      onUpdateJournalEntryType,
      onResetNewJournal,
      applicationSettingsDigest,
      showSuccessPopup,
      setFinshedSchedules,
    } = this.props;
    const {
      labsEntryTypeId,
      quickNoteEntryTypeId,
      newJournalEntryTypeId,
    } = applicationSettingsDigest;

    this.setState({
      saveWait: false,
      emptyConfirm: false,
      isNew: false,
    });
    if (data.userDashboard) {
      updateDashboardGraphSuccess(data.userDashboard);
      if (
        data.userDashboard.status === 'ENABLED' &&
        data.journalEntryType?.showOnDashboard
      ) {
        onSelectGraphItem(data.userDashboard.id);
        onHideShowGraph(true);
      }
    }
    if (data.journalEntryType) {
      onUpdateJournalEntryType(data.journalEntryType);
    }
    if (data.newTreatments && data.newTreatments.length > 0) {
      data.newTreatments.forEach(treat => updateTreatments(treat));
    }
    if (data.newSymptoms && data.newSymptoms.length > 0) {
      data.newSymptoms.forEach(sympt => updateUserSymptom(sympt));
    }
    if (newMetrics && newMetrics.length > 0) {
      data.newMetrics.forEach(metr => {
        updateUserMetrics(metr);
        updateUserMetricUOM(metr.metricDisplayUnitOfMeasure);
      });
    }
    if (Boolean(APP_FEATURE_DASHBOARD_SCHEDULE_ENABLED)) {
      setFinshedSchedules(finishedScheduleList);
    }
    navigation.reset({
      index: 0,
      routes: [{ name: DASHBOARD.path }],
    });
    onResetNewJournal();
    switch (requestType) {
      case journalSaveOptions.saveAndUpdateRoutine:
        pendoTrackEvent('Journal@ > UpdateRoutine');
        showSuccessPopup(9, data?.userDashboard?.id);
        break;
      case journalSaveOptions.saveAndCreateRoutine:
        pendoTrackEvent(
          newJournalEntryTypeId === this.journalEntryTypes?.id
            ? 'NewJournal > NewRoutine'
            : 'Journal@ > NewRoutine'
        );
        showSuccessPopup(10, data?.userDashboard?.id);
        break;
      default:
        pendoTrackEvent(
          labsEntryTypeId === this.journalEntryTypes?.id ||
            quickNoteEntryTypeId === this.journalEntryTypes?.id
            ? 'CreateRoutine > Save'
            : 'Journal@ > Save'
        )
        showSuccessPopup(7, data?.userDashboard?.id);
        break;
    }
  }

  filterCompletedOverdueScheduleList = (entryTypeId, dayDiff, reminderDateTime) => {
    const { dashboardScheduleData, finishedSchedules = [] } = this.props;
    const filteredSchedules = Boolean(dashboardScheduleData && dashboardScheduleData?.length > 0) && dashboardScheduleData.filter(schedule => schedule.entryTypeId === entryTypeId && schedule?.scheduleTime === -dayDiff && schedule?.reminderDateTime === reminderDateTime)
    return Boolean(filteredSchedules && filteredSchedules?.length > 0)
      ? [...finishedSchedules, ...filteredSchedules]
      : finishedSchedules;
  }

  copyPreviousJournalEntry = () => {
    const {
      journalEntriesProcessed,
      onUpdateJournalEntryState,
      newJournal = {},
      symptomsDigestProcessed,
      metricsDigestProcessed,
      treatmentsProcessed
    } = this.props;
    let filterdJournalEntries = Object.values(journalEntriesProcessed)
      .filter(res => res.entryType === this.dataRef.entryType)
      .sort((a, b) => b.entryDate - a.entryDate);

    if (filterdJournalEntries.length > 0) {
      let isNewJournal = true;
      if (
        filterdJournalEntries.filter(res => res.id === newJournal.id).length > 0
      ) {
        isNewJournal = false;
      }
      if (!isNewJournal) {
        filterdJournalEntries = filterdJournalEntries.filter(
          res => res.entryDate < newJournal.entryDate,
        );
      }
    }
    if (filterdJournalEntries.length > 0) {
      let selectedPrevious = filterdJournalEntries[0];
      const { dosages, symptoms, metrics } = selectedPrevious;

      this.dataRef.dosagesMedication = actions.getSortedMedicationDosagesWithTreatmentObject(dosages, true, treatmentsProcessed);
      this.dataRef.dosagesActivity = actions.getSortedActivityDosagesWithTreatmentObject(dosages, true, treatmentsProcessed);
      this.dataRef.symptoms = actions.getSortedSymptomsWithSymptomObject(symptoms, true, symptomsDigestProcessed);
      this.dataRef.metrics = actions.getSortedMetricsWithSymptomObject(metrics, true, metricsDigestProcessed);
      //this.entry = entry;
      this.dataRef.entry = null;
      var dosagesNew = this.dataRef.dosagesMedication.concat(this.dataRef.dosagesActivity);
      this.setState({
        copyPreviousEntry: true,
        copyPreviousSymptoms: true,
        copyPreviousMetrics: true,
        copyPreviousMedication: true,
        copyPreviousActivity: true,
      });

      onUpdateJournalEntryState(
        {
          ...newJournal,
          //entry: entry,
          entry: null,
          metrics: this.dataRef.metrics,
          symptoms: this.dataRef.symptoms,
          dosages: dosagesNew,
        },
        () => {
          this.updateIsEmptyJournal();
        },
      );
    }
  };

  handleOnSubmitCancel = () => {
    // const changeHash = this.getJournalHashValue();
    // const {isEmptyJournal} = this.state;

    // if (this.hasValue === changeHash || isEmptyJournal) {
    //   if (
    //     (this.state.isNew && this.journalHasAttachments(this.artifactId)) ||
    //     this.entryDate !== initialDate
    //   ) {
    //     this.setState({saveOrDoNotSaveConfirm: true});
    //   } else {
    //     this.deleteNewJournal();
    //   }
    // } else {
    //   this.setState({saveOrDoNotSaveConfirm: true});
    // }
    if (this.hasDataChange() && this.props.emailVerified) {
      this.setState({ saveOrDoNotSaveConfirm: true });
    } else {
      this.deleteNewJournal();
    }
  };

  hasDataChange = () => {
    const changeHash = this.getJournalHashValue();
    const { isEmptyJournal } = this.state;

    if (this.dataRef.hasValue === changeHash || isEmptyJournal) {
      if (
        this.state.isNew || this.journalHasAttachments(this.dataRef.artifactId) ||
        this.dataRef?.entryDate !== initialDate
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  isCopyPreviousEntry = () => {
    const { copyPreviousEntry } = this.state;
    this.setState({ copyPreviousEntry: false });
    return copyPreviousEntry;
  };

  isCopyPreviousActivity = () => {
    const { copyPreviousActivity } = this.state;
    this.setState({ copyPreviousActivity: false });
    return copyPreviousActivity;
  };

  isCopyPreviousMedication = () => {
    const { copyPreviousMedication } = this.state;
    this.setState({ copyPreviousMedication: false });
    return copyPreviousMedication;
  };

  isCopyPreviousSymptoms = () => {
    const { copyPreviousSymptoms } = this.state;
    this.setState({ copyPreviousSymptoms: false });
    return copyPreviousSymptoms;
  };

  isCopyPreviousMetrics = () => {
    const { copyPreviousMetrics } = this.state;
    this.setState({ copyPreviousMetrics: false });
    return copyPreviousMetrics;
  };

  handleDeleteJournal = () => {
    this.setState({ deleteWait: true });
    const {
      newJournal = {},
      journalEntriesProcessed,
      journalUserId,
      onDeleteJournal,
      onResetNewJournal,
    } = this.props;
    const { id } = newJournal;

    onDeleteJournal(
      id,
      journalEntriesProcessed,
      res => {
        this.setState({
          deleteWait: false,
          deleteConfirm: false,
          isNew: false,
          alertMessageTitle: null,
          saveOrDoNotSaveConfirm: false,
        });
        if (res) {
          onResetNewJournal();
          this.handleBackNavigation();
        }
      },
      journalUserId,
    );
  };

  deleteNewJournal = () => {
    const {
      newJournal,
      onDeleteJournal,
      journalEntriesProcessed,
      journalUserId,
      onResetNewJournal,
      setFromPopup,
    } = this.props;

    const { isNew, fromPopup } = this.state;
    this.setState({ loadWait: true }, () => {
      if (newJournal) {
        if (isNew && isNew !== null) {
          const { id } = newJournal;
          onDeleteJournal(
            id,
            journalEntriesProcessed,
            res => {
              onResetNewJournal();
              this.handleBackNavigation();
              if (fromPopup) {
                setFromPopup(fromPopup);
              }
            },
            journalUserId,
          );
        } else {
          this.handleDiscardChanges();
        }
      }
    });
  };

  handleBackNavigation = () => {
    const { windowWidth, navigation, route, deleteSuccess, journalEntrySelected } = this.props;
    if (windowWidth < 1024) {
      if (route?.params &&
        route?.params?.from &&
        route?.params?.from === JOURNALS.path
      ) {
        AddRoutineDetails.eliminateRoutesAndNavigate(
          navigation,
          {
            from: route.name,
            fromWindowResize: false
          },
          [JOURNALS.path, JOURNALS.addJournalPath],
          JOURNALS.path,
        );
      } else {
        handleCommonBackNavigation(navigation);
      }
    } else {
      deleteSuccess();
      if (this.props.route?.params &&
        this.props.route?.params?.from &&
        (this.props.route?.params?.from === JOURNALS.path || 
        this.props.route?.params?.from === JOURNALS.addJournalPath)
      ) {
        AddRoutineDetails.eliminateRoutesAndNavigate(
          navigation,
          {
            journalPath: JOURNALS.viewJournalPathSuffix,
            journalName: journalEntrySelected?.title,
            fromWindowResize: false
          },
          [JOURNALS.path, JOURNALS.addJournalPath],
          JOURNALS.path,
        );
      } else {
        handleCommonBackNavigation(navigation);
      }
    }
  }

  componentWillUnmount = () => {
    const { resetDuplicates } = this.props;
    resetDuplicates();
  };

  handleRedirectMedications = () => {
    const { navigation, editRoutineTemplate } = this.props;
    let newTemplate = this.getNewTemplate();
    editRoutineTemplate(newTemplate);
    navigation.push(
      MEDICATIONS.viewTherapiesPath, {
      from: this.props.route.name
    });
  };

  getNewTemplate = () => {
    let symptomsFilter = this.dataRef.symptoms;
    let symptomsFilterArr = [];

    let metricsFilter = this.dataRef.metrics;
    let metricsFilterArr = [];

    let dosagesFilterMedication = this.dataRef.dosagesMedication;
    let dosagesFilterActivity = this.dataRef.dosagesActivity;
    if (symptomsFilter.length !== 0) {
      symptomsFilter.forEach((res, index) => {
        let temp = {
          id: res.id !== -1 ? res.id : -index,
          createdOn: res.createdOn,
          modifiedOn: res.modifiedOn,
          artifactId: res.artifactId,
          symptom: res.symptom,
          severity: res.severity,
          startTime: res.startTime,
          endTime: res.endTime,
          userArtifactType: res.userArtifactType,
        };
        symptomsFilterArr.push(temp);
      });
    }
    if (metricsFilter.length !== 0) {
      metricsFilter.forEach((res, index) => {
        let temp = {
          id: res.id !== -1 ? res.id : -index,
          createdOn: res.createdOn,
          modifiedOn: res.modifiedOn,
          artifactId: res.artifactId,
          metric: res.metric,
          value: res.value,
          unitOfMeasure: res.unitOfMeasure,
          isActive: res.isActive,
          userArtifactType: res.userArtifactType,
          active: res.active,
        };
        metricsFilterArr.push(temp);
      });
    }

    const { newJournal = {} } = this.props;
    let newTemplate = {
      id: newJournal.id,
      createdOn: newJournal.createdOn,
      modifiedOn: newJournal.modifiedOn,
      artifactId: newJournal.artifactId,
      entry: this.dataRef.entry,
      entryDate: this.dataRef.entryDate,
      endDate: newJournal.endDate,
      entryType: newJournal.entryType,
      title: this.dataRef.title,
      description: this.dataRef.description,
      flagged: this.dataRef.flagged,
      healthProvider: this.dataRef.healthProvider,
      appointmentType: null,
      labReportType: this.dataRef.labReportType,
      surveys: [],
      symptoms: symptomsFilterArr,
      metrics: metricsFilterArr,
      dosagesActTemp:
        dosagesFilterActivity.length !== 0 ? dosagesFilterActivity : [],
      dosagesTemp:
        dosagesFilterMedication.length !== 0 ? dosagesFilterMedication : [],
      userArtifactType: newJournal.userArtifactType,
    };
    return newTemplate;
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { windowHeight, windowWidth, route, navigation } = nextProps;
    if (
      ((route?.params &&
        route?.params.from &&
        (route?.params.from === JOURNALS.path ||
          route?.params.from === JOURNALS.viewJournalPath)) ||
        route.name === JOURNALS.addJournalPath) &&
      windowWidth &&
      windowWidth > TAB_BREAk_MAX_WIDTH
    ) {
      navigation.push(
        JOURNALS.path, {
        journalPath: JOURNALS.addJournalPathSuffix,
        journalName: this.dataRef.title
      }
      );
    }

    if (!nextProps.newJournal && windowWidth < TAB_BREAk_MAX_WIDTH)
      this.props.navigation.replace(JOURNALS.path);

    this.setState({
      windowHeight: windowHeight,
      windowWidth: windowWidth,
    });
  }

  updateFlagged = val => {
    this.dataRef.flagged = val;
  };

  disabledScroll = (val, callBack) => {
    this.setState({ isScrollDisabled: val }, () => {
      if (callBack) {
        callBack();
      }
    });
  };

  onPressCreateRoutine = opt => {
    this.setState({ opt: opt, saveWait: true }, () => {
      this.handleOnSubmitSave();
    });
  };

  handleDiscardChanges = () => {
    const { windowWidth, onResetNewJournal, navigation, deleteSuccess } = this.props;
    onResetNewJournal();
    if (windowWidth < TAB_BREAk_POINT_END) {
      if (this.props.route?.params &&
        this.props.route?.params?.from &&
        this.props.route?.params?.from === JOURNALS.path
      ) {
        navigation.navigate(JOURNALS.path, {
          from: this.props.route.name,
          fromWindowResize: false,
        });
      } else {
        handleCommonBackNavigation(navigation);
      }
    } else {
      deleteSuccess();
    }
  };

  selectedTab = tabid => {
    this.props.setActiveTab(tabid);
    if (tabid === 1) {
      this.props.fetchUserAttachments(this.dataRef.artifactId);
    }
  };

  openCroper = (image) => {
    this.setState({
      showCropper: true,
      image: image,
    });
  }

  closeCroper = (croppedUri, newWidth, newHeight) => {
    if (croppedUri) {
      this.setState({
        showCropper: false,
        image: { ...this.state.image, uri: croppedUri, height: newHeight, width: newWidth },
      });
    } else {
      this.setState({
        showCropper: false,
        image: { ...this.state.image },
      });
    }
  }

  imageUploadCompleted = () => {
    this.setState({ image: null });
  }

  removeHealthdataErrors = (index) => {
    this.setState(prevState => ({ healthdataErrors: [...prevState.healthdataErrors.map((item, i) => i === index ? false : item)] }))
  }

  handleDeleteError = (index) => {
    const { healthdataErrors } = this.state;
    delete healthdataErrors[index];
    this.setState({ healthdataErrors });
  }

  render() {
    const { styles: stylesRes, ids } = stylesResponsive.getProcessedStyles();
    const {
      applicationSettingsDigest,
      hasNotch,
      windowWidth,
      navigation,
      questionList,
      saveDisable,
      route,
    } = this.props;

    const {
      labsEntryTypeId,
      quickNoteEntryTypeId,
      newJournalEntryTypeId,
    } = applicationSettingsDigest;

    let smScreenMaxWidth = 1023;
    let outlookTabView = false;
    let outlookTabViewSmall = false;
    let styleBottom = styles.btnPdSmFixed;
    if (windowWidth >= smScreenMaxWidth) {
      outlookTabView = true;
      styleBottom = styles.btnPdLgFixed;
    } else {
      outlookTabViewSmall = true;
    }



    return (
      this.props.newJournal && this.state.showCropper
        ? <Cropper
          image={this.state.image}
          close={this.closeCroper}
        />
        : <View style={[styles.pageBodyWrapper]} onTouchStart={() => this.props.onTouchRoutine(true)}>
          {this.props.newJournal &&
            (this.state.loadWait ? (
              <ActivityIndicatorCustom pdZero={true} />
            ) : (
              <Fragment>
                <Header
                  showFilers={false}
                  showBack={true}
                  showClearFilters={false}
                  showNotify={true}
                  handleOnPressBackButton={() => {
                    this.handleOnSubmitCancel();
                  }}
                  index={JOURNALS.index}
                  navigation={navigation}
                  boldheading={true}
                  route={route}
                  hideCaregiverAccess={true}
                  customHeading="Create an Entry"
                />
                <SpaceBar />
                {this.hasDataChange() ?
                  (<MenuOverlayContainer onPressOpen={this.handleOnSubmitCancel} />)
                  : null}
                <StorageBanner />
                {this.dataRef.journalEntryTypes !== undefined ? (
                  <>
                    <KeyboardAwareScrollViewHoc
                      hasNotch={hasNotch}
                      extraScrollHeight={170}
                      // onScroll={() => {
                      //   scrollEvent();
                      // }}
                      scrollEnabled={this.state.isScrollDisabled}
                      onContentSizeChange={() => scrollEvent()}
                      ref={val => (this.scrollViewRef = val)}>
                      <View style={styles.gridSection}>
                        <JournalTemplateHeader
                          date={this.dataRef?.entryDate}
                          journalEntryTypes={this.dataRef.journalEntryTypes}
                          updateEntryDate={this.updateEntryDate}
                          description={this.dataRef.description}
                          title={this.dataRef.title}
                          updateTitel={this.updateTitle}
                          updateDescription={this.updateDescription}
                          copyPreviousJournalEntry={this.copyPreviousJournalEntry}
                          windowWidth={this.state.windowWidth}
                          validation={this.state.validation}
                          flagged={this.dataRef.flagged}
                          updateFlagged={this.updateFlagged}
                          deviceJournal={this.state.deviceJournal}
                          reminderDateTime={Boolean(this.reminderDateTime) ? this.reminderDateTime : Boolean(this.props.newJournal?.createdByNotification) && Boolean(this.props.newJournal?.reminderDateTime) ? this.props.newJournal?.reminderDateTime : null}
                        />
                        <Tabs
                          pageContainer={true}
                          smScreenFull={true}
                          outlookScreen={true}
                          screenFull={true}
                          activeTab={this.props.activeTab}
                          tabIndex={(res) => this.selectedTab(res)}
                          outlookTabView={outlookTabView}
                          outlookTabViewSmall={outlookTabViewSmall}>
                          {/* Journal tab */}
                          <View
                            title="Entry"
                            style={[styles.tabContentSection]}
                            nativeId={'journalJournal'}>
                            {this.dataRef.journalEntryTypes?.exportNotes &&
                              <View style={[stylesRes.journalWarningSpacing]} dataSet={{ media: ids.journalWarningSpacing }}>
                                <View style={[styles.journalWarningborder]}>
                                  <View style={[styles.borderMgLtRt, styles.journalWarningContent]}>
                                    <WarningNotes
                                      errorDescription={'Notes included in this journal are viewable to your clinical trial researchers.'}
                                      journalWarningBanner={true}
                                    />
                                  </View>
                                </View>
                              </View>
                            }
                            <View style={styles.gridRow}>
                              <View style={styles.gridColFull}>
                                <PageContainer
                                  smScreenFull={true}
                                  outlookScreen={true}
                                  screenDynamic={true}>
                                  {labsEntryTypeId ===
                                    this.dataRef.journalEntryTypes?.id && (
                                      <LabReportDetails
                                        healthProvider={this.dataRef.healthProvider}
                                        healthProvidername={this.dataRef.healthProviderName}
                                        labReportType={this.dataRef.labReportType}
                                        updateHealthProvider={
                                          this.updateHealthProvider
                                        }
                                        updateLabReportType={
                                          this.updateLabReportType
                                        }
                                        windowWidth={this.state.windowWidth}
                                      />
                                    )}
                                  <View
                                    style={[styles.secArea, styles.bgThinGrey]}>
                                    <Medications
                                      dosages={this.dataRef.dosagesMedication}
                                      updateMedication={this.updateMedication}
                                      handleRedirectMedications={
                                        this.handleRedirectMedications
                                      }
                                      plusButton={true}
                                      type={'R'}
                                      windowWidth={this.state.windowWidth}
                                      isCopyPrevious={
                                        this.isCopyPreviousMedication
                                      }
                                      disabledScroll={this.disabledScroll}
                                      isNew={this.props.isNew}
                                    />
                                    <View style={[styles.subSection]}>
                                      <Activity
                                        dosages={this.dataRef.dosagesActivity}
                                        updateActivity={this.updateActivity}
                                        plusButton={true}
                                        type={'R'}
                                        isCopyPrevious={
                                          this.isCopyPreviousActivity
                                        }
                                        disabledScroll={this.disabledScroll}
                                        handleRedirectActivity={
                                          this.handleRedirectMedications
                                        }
                                        windowWidth={this.state.windowWidth}
                                        isNew={this.props.isNew}
                                      />

                                      <View style={[styles.subSection]}>
                                        <Symptoms
                                          symptoms={this.dataRef.symptoms}
                                          updateSymptoms={this.updateSymptoms}
                                          plusButton={true}
                                          type={'R'}
                                          isCopyPrevious={
                                            this.isCopyPreviousSymptoms
                                          }
                                          disabledScroll={this.disabledScroll}
                                        />

                                        <View style={[styles.subSection]}>
                                          <HealthData
                                            metrics={this.dataRef.metrics}
                                            updateHealthData={
                                              this.updateHealthData
                                            }
                                            plusButton={true}
                                            type={'R'}
                                            windowWidth={this.state.windowWidth}
                                            isCopyPrevious={
                                              this.isCopyPreviousMetrics
                                            }
                                            disabledScroll={this.disabledScroll}
                                            healthdataErrors={this.state.healthdataErrors}
                                            removeHealthdataErrors={this.removeHealthdataErrors}
                                            handleDeleteError={this.handleDeleteError}
                                            deviceJournal={this.state.deviceJournal}
                                          />

                                          <View style={[styles.subSection]}>
                                            <Notes
                                              entry={this.dataRef.entry}
                                              updateEntry={this.updateEntry}
                                              isCopyPrevious={
                                                this.isCopyPreviousEntry
                                              }
                                            />
                                            <Questions
                                              artifactId={this.dataRef.artifactId}
                                            />
                                            <View
                                              style={[
                                                styles.secSepBorderBot,
                                                styles.zIndexMin,
                                              ]}
                                            />
                                            <View
                                              style={[
                                                styles.btnArea,
                                                styles.bgWhite,
                                                // styles.btnPdSmFixed,
                                                styleBottom,
                                              ]}>
                                              <View style={stylesRes.btnViewSec}>
                                                <View style={styles.fieldBtn}>
                                                  <View
                                                    style={styles.btnBlockFull}>
                                                    <CancelButton
                                                      touchableOnpress={this.handleOnSubmitCancel}
                                                      disabled={this.state.saveWait}
                                                    />
                                                  </View>
                                                </View>
                                                <View
                                                  style={styles.fieldBtnSpSm}
                                                />
                                                <View style={styles.fieldBtn}>
                                                  <View
                                                    style={styles.btnBlockFull}>
                                                    <TouchableOpacity
                                                      style={[
                                                        styles.submitSm,
                                                        styles.btnShadowCom,
                                                        this.state.saveWait ||
                                                          (this.state
                                                            .isEmptyJournal &&
                                                            !this.journalHasAttachments(
                                                              this.dataRef.artifactId,
                                                            ) &&
                                                            questionList &&
                                                            questionList.length ===
                                                            0)
                                                          ? styles.submitBgOrangeLight
                                                          : saveDisable
                                                            ? [
                                                              styles.submitBgOrange,

                                                              styles.btnBlurState,
                                                            ]
                                                            : styles.submitBgOrange,
                                                      ]}
                                                      underlayColor="rgba(0, 153, 168, 0.8)"
                                                      onPress={
                                                        this.handleOnSubmitSave
                                                      }
                                                      disabled={
                                                        saveDisable ||
                                                        this.state.saveWait ||
                                                        (this.state
                                                          .isEmptyJournal &&
                                                          !this.journalHasAttachments(
                                                            this.dataRef.artifactId,
                                                          ) &&
                                                          questionList &&
                                                          questionList.length ===
                                                          0)
                                                      }
                                                      nativeID={
                                                        labsEntryTypeId === this.dataRef.journalEntryTypes?.id ||
                                                          quickNoteEntryTypeId === this.dataRef.journalEntryTypes?.id
                                                          ? 'createRoutineSave'
                                                          : 'journalSave'
                                                      }>
                                                      <View
                                                        style={[
                                                          styles.btnSingleView,
                                                          labsEntryTypeId !==
                                                          this.dataRef.journalEntryTypes
                                                            .id &&
                                                          quickNoteEntryTypeId !==
                                                          this.dataRef
                                                            .journalEntryTypes
                                                            .id &&
                                                          styles.btnSingleViewCom,
                                                        ]}
                                                      >
                                                        <View
                                                          style={[
                                                            styles.btnSingle,
                                                            styles.btnSingleCent,

                                                            labsEntryTypeId !==
                                                            this.dataRef
                                                              .journalEntryTypes
                                                              .id &&
                                                            quickNoteEntryTypeId !==
                                                            this.dataRef
                                                              .journalEntryTypes
                                                              .id && [
                                                              styles.flexAsfs,
                                                              styles.btnSubmitWithIcon,
                                                            ],
                                                          ]}
                                                        >
                                                          {this.state
                                                            .saveWait && (
                                                              <View
                                                                style={[
                                                                  styles.indicatorIconView,
                                                                  labsEntryTypeId !==
                                                                    this.dataRef
                                                                      .journalEntryTypes
                                                                      .id &&
                                                                    quickNoteEntryTypeId !==
                                                                    this.dataRef
                                                                      .journalEntryTypes
                                                                      .id
                                                                    ? styles.submitLoaderSm
                                                                    : styles.btnSubmitloaderCom,
                                                                ]}>
                                                                <ActivityIndicatorCustom
                                                                  pdZero={true}
                                                                  size="small"
                                                                />
                                                              </View>
                                                            )}
                                                          <View
                                                            style={
                                                              styles.btnSubmitSec
                                                            }>
                                                            <Text
                                                              style={[
                                                                styles.submitBtnTextWhite,
                                                                styles.bold,
                                                              ]}
                                                              allowFontScaling={false}
                                                            >
                                                              {comTitles.SAVE}
                                                            </Text>
                                                          </View>
                                                        </View>
                                                      </View>
                                                      {labsEntryTypeId !==
                                                        this.dataRef
                                                          .journalEntryTypes
                                                          .id &&
                                                        quickNoteEntryTypeId !==
                                                        this.dataRef
                                                          .journalEntryTypes
                                                          .id && (
                                                          <View
                                                            style={
                                                              styles.btnMoreViewCom
                                                            }>
                                                            <View>
                                                              <ButtonPopupMenu
                                                                showCreateRoutine={
                                                                  newJournalEntryTypeId !==
                                                                  this.dataRef
                                                                      .journalEntryTypes
                                                                      .id
                                                                    ? true
                                                                    : false
                                                                }
                                                                onPressCreateRoutine={(e) => {
                                                                  if (!this.state.saveWait) {
                                                                    this.setState({ saveWait: true }, () => { this.onPressCreateRoutine(e) })
                                                                  }
                                                                }}
                                                                disabled={
                                                                  saveDisable ||
                                                                  this.state
                                                                    .saveWait ||
                                                                  (this
                                                                    .state
                                                                    .isEmptyJournal &&
                                                                    !this.journalHasAttachments(
                                                                      this.dataRef
                                                                        .artifactId,
                                                                    ) &&
                                                                    questionList &&
                                                                    questionList.length ===
                                                                    0)
                                                                }
                                                              />
                                                            </View>
                                                          </View>
                                                        )}
                                                    </TouchableOpacity>
                                                  </View>
                                                </View>
                                              </View>
                                            </View>
                                            {/* Autosugest Open */}
                                            <SpaceBarBottom
                                              windowWidth={this.state.windowWidth}
                                              viewBg={FieldDetails.BGWHITE}
                                            />
                                          </View>
                                        </View>
                                      </View>
                                    </View>
                                  </View>
                                </PageContainer>
                              </View>
                            </View>
                          </View>
                          {/* Attachments tab */}
                          <View
                            title="Attachments"
                            activeCircle={this.journalHasAttachments(
                              this.dataRef.artifactId,
                            )}
                            fetchAttachmentSpinner={
                              this.props.fetchAttachmentSpinner
                            }
                            style={[styles.tabContentSection, styles.comStyling]}
                            attachmentCategory="Journal"
                            nativeId={'journalAttachments'}>
                            <PageContainer
                              smScreenFull={true}
                              outlookScreen={true}
                              screenDynamic={true}>
                              <View style={styles.attachWrapper}>
                                <Attachments
                                  attachmentCategory="Journal"
                                  attachmentSpaceZero={true}
                                />
                                <AddAttachment
                                  attachmentCategory="Journal"
                                  btnText={'Add'}
                                  btnPosition={'left'}
                                  openCroper={this.openCroper}
                                  tempCapturedImage={this.state.image}
                                  onUploadCompleted={this.imageUploadCompleted}
                                />
                              </View>
                              {/* Button section start */}
                              <View style={[styles.btnArea, styles.bgWhite]}>
                                <View style={stylesRes.btnViewSec}>
                                  <View style={styles.fieldBtn}>
                                    <View style={styles.btnBlockFull}>
                                      <CancelButton
                                        touchableOnpress={this.handleOnSubmitCancel}
                                        disabled={this.state.saveWait}
                                      />
                                    </View>
                                  </View>
                                  <View style={styles.fieldBtnSpSm} />
                                  <View style={styles.fieldBtn}>
                                    <View style={styles.btnBlockFull}>
                                      <TouchableOpacity
                                        style={[
                                          styles.submitSm,
                                          styles.btnShadowCom,
                                          this.state.saveWait ||
                                            (this.state.isEmptyJournal &&
                                              !this.journalHasAttachments(
                                                this.dataRef.artifactId,
                                              ) &&
                                              questionList &&
                                              questionList.length === 0)
                                            ? styles.submitBgOrangeLight
                                            : saveDisable
                                              ? [
                                                styles.submitBgOrange,
                                                styles.btnBlurState,
                                              ]
                                              : styles.submitBgOrange,
                                        ]}
                                        underlayColor="rgba(0, 153, 168, 0.8)"
                                        onPress={this.handleOnSubmitSave}
                                        disabled={
                                          saveDisable ||
                                          this.state.saveWait ||
                                          (this.state.isEmptyJournal &&
                                            !this.journalHasAttachments(
                                              this.dataRef.artifactId,
                                            ) &&
                                            questionList &&
                                            questionList.length === 0)
                                        }
                                        nativeID={
                                          labsEntryTypeId === this.dataRef.journalEntryTypes?.id ||
                                            quickNoteEntryTypeId === this.dataRef.journalEntryTypes?.id
                                            ? 'createRoutineSave'
                                            : 'journalSave'
                                        }>
                                        <View
                                          style={[
                                            styles.btnSingleView,

                                            labsEntryTypeId !==
                                            this.dataRef.journalEntryTypes?.id &&
                                            quickNoteEntryTypeId !==
                                            this.dataRef.journalEntryTypes?.id &&
                                            styles.btnSingleViewCom,
                                          ]}
                                        >
                                          <View
                                            style={[
                                              styles.btnSingle,
                                              styles.btnSingleCent,

                                              labsEntryTypeId !==
                                              this.dataRef.journalEntryTypes?.id &&
                                              quickNoteEntryTypeId !==
                                              this.dataRef.journalEntryTypes?.id && [
                                                styles.flexAsfs,
                                                styles.btnSubmitWithIcon,
                                              ],
                                            ]}
                                          >
                                            {this.state.saveWait && (
                                              <View
                                                style={[
                                                  styles.indicatorIconView,
                                                  labsEntryTypeId !==
                                                    this.dataRef.journalEntryTypes?.id &&
                                                    quickNoteEntryTypeId !==
                                                    this.dataRef.journalEntryTypes?.id
                                                    ? styles.submitLoaderSm
                                                    : styles.btnSubmitloaderCom,
                                                ]}
                                              >
                                                <ActivityIndicatorCustom
                                                  pdZero={true}
                                                  size="small"
                                                />
                                              </View>
                                            )}
                                            <View
                                              style={
                                                styles.btnSubmitSec
                                              }>
                                              <Text
                                                style={[
                                                  styles.submitBtnTextWhite,
                                                  styles.bold,
                                                ]}
                                                allowFontScaling={false}
                                              >
                                                {comTitles.SAVE}
                                              </Text>
                                            </View>
                                          </View>
                                        </View>
                                        {labsEntryTypeId !==
                                          this.dataRef.journalEntryTypes?.id &&
                                          quickNoteEntryTypeId !==
                                          this.dataRef.journalEntryTypes?.id && (
                                            <View
                                              style={styles.btnMoreViewCom}>
                                              <View>
                                                <ButtonAttachmentPopupMenu
                                                  showCreateRoutine={
                                                    newJournalEntryTypeId !==
                                                      this.dataRef.journalEntryTypes
                                                        .id
                                                      ? true
                                                      : false
                                                  }
                                                  onPressCreateRoutine={(e) => {
                                                    if (!this.state.saveWait) {
                                                      this.setState({ saveWait: true }, () => { this.onPressCreateRoutine(e) })
                                                    }
                                                  }}
                                                  disabled={
                                                    saveDisable ||
                                                    this.state.saveWait ||
                                                    (this.state
                                                      .isEmptyJournal &&
                                                      !this.journalHasAttachments(
                                                        this.dataRef.artifactId,
                                                      ) &&
                                                      questionList &&
                                                      questionList.length ===
                                                      0)
                                                  }
                                                />
                                              </View>
                                            </View>
                                          )}
                                      </TouchableOpacity>
                                    </View>
                                  </View>
                                </View>
                              </View>
                              {/* Button section end */}
                            </PageContainer>
                            <View style={styles.spaceBarBotCom} />
                          </View>
                        </Tabs>
                      </View>
                    </KeyboardAwareScrollViewHoc>
                    {this.state.saveOrDoNotSaveConfirm && (
                      <SavingConfirmation
                        visibleModal={this.state.saveOrDoNotSaveConfirm}
                        alertBody={alertBody}
                        alertTitle={journalTitle}
                        handleCancel={
                          this.state.isNew
                            ? this.handleDeleteJournal
                            : this.handleDiscardChanges
                        }
                        handleConfirm={() => {
                          this.setState({ saveOrDoNotSaveConfirm: false });
                          this.handleOnSubmitSave();
                        }}
                        confirmWait={this.state.saveWait}
                        deleteWait={this.state.deleteWait}
                        onBackdropPress={() => {
                          this.setState({ saveOrDoNotSaveConfirm: false });
                        }}
                        disabled={saveDisable || this.state.deleteWait || 
                          this.state.saveWait ||
                          (this.state.isEmptyJournal &&
                            !this.journalHasAttachments(this.dataRef.artifactId) &&
                            questionList && questionList.length === 0)
                        }
                      />
                    )}
                    {this.state.emptyConfirm && (
                      <EmptyPopup
                        visibleModal={this.state.emptyConfirm}
                        onBackdropPress={() => {
                          this.setState({ emptyConfirm: false });
                        }}
                        onRequestClose={() => {
                          this.setState({ emptyConfirm: false });
                        }}
                        handleCancel={() => {
                          this.setState({ emptyConfirm: false });
                        }}
                        handleConfirm={() => {
                          this.setState(
                            { discardEmpty: true, emptyConfirm: false },
                            () => {
                              if (this.state.opt) {
                                this.onPressCreateRoutine(this.state.opt);
                              } else {
                                this.handleOnSubmitSave();
                              }
                            },
                          );
                        }}
                        symptomNames={this.state.emptySymptomNames}
                        dosagesNames={this.state.emptyDosagesNames}
                        metricsNames={this.state.emptyMetricsNames}
                      />
                    )}
                  </>
                ) : (
                  <View style={styles.gridSection}>
                    <Text style={[styles.bold]} allowFontScaling={false}>
                      Oops! Sorry this routine type was not found in this profile.
                    </Text>
                    <Text allowFontScaling={false}>
                      Please make sure you have the right profile selected.
                    </Text>
                  </View>
                )}
              </Fragment>
            ))}
          {/* {
           this.props.newJournal && this.props.history.replace(JOURNALS.path)
          } */}
        </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    newJournal: state.routineTemplate.newJournal,
    journalEntryTypesProcessed: state.routines.journalEntryTypesProcessed,
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    treatmentsProcessed: state.routines.treatmentsProcessed,
    journalUserId: state.uistate.journalUserId,
    isNew: state.routineTemplate.isNew,
    applicationSettingsDigest: state.routines.applicationSettingsDigest,
    hasNotch: state.uistate.hasNotch,
    symptomsDigestProcessed: state.routines.symptomsDigestProcessed,
    metricsDigestProcessed: state.routines.metricsDigestProcessed,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    attachmentsCompleteDigest: state.routines.attachmentsCompleteDigest,
    fetchAttachmentSpinner: state.routines.journalAttachmentSpinner,
    questionList: state.routineTemplate.questionList,
    saveDisable: state.therapie.saveDisable,
    activeTab: state.surveys.activeTab,
    userId: state.routines.user.id,
    journalEntrySelected: state.routines.journalEntrySelected,
    temporyQuestionObject: state.routineTemplate?.temporyQuestionObject,
    temporyEntryObject: state.routineTemplate?.temporyEntryObject,
    finishedSchedules: state.uistate.finishedSchedules,
    dashboardScheduleData: state.uistate.dashboardScheduleData?.routines ?? [],
    contacts: state.routines.contacts,
    emailVerified: state.auth.emailVerified,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSaveJournalEntry: (
      journalEntry,
      callBack,
      journalUserId,
      deletedSymptomIds,
      isFromCopyToNew,
      isFromOverdueAddhocEntry
    ) =>
      dispatch(
        actions.saveJournalEntry(
          journalEntry,
          callBack,
          journalUserId,
          deletedSymptomIds,
          isFromCopyToNew,
          isFromOverdueAddhocEntry
        ),
      ),
    onResetNewJournal: () => dispatch(actions.resetNewJournal()),
    onUpdateJournalEntryState: (journalEntry, callBack) =>
      dispatch(actions.updateJournalEntryState(journalEntry, callBack)),
    setEntryType: journalEntryType =>
      dispatch(actions.setSelectedJournalEntryType(journalEntryType)),
    onDeleteJournal: (journalId, journalEntriesProcessed, callBack, journalUserId) =>
      dispatch(
        actions.deleteJournal(
          journalId,
          journalEntriesProcessed,
          callBack,
          journalUserId,
        ),
      ),
    showSuccessPopup: (id, graphId) =>
      dispatch(actions.showSuccessPopup(id, graphId)),
    editRoutineTemplate: journalEntrie =>
      dispatch(actions.editRoutineTemplate(journalEntrie)),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
    setFromPopup: fromPopup => dispatch(actions.setFromPopup(fromPopup)),
    updateTreatments: treatment =>
      dispatch(actions.updateTreatments(treatment)),
    updateUserSymptom: symptom => dispatch(actions.updateNewSymptom(symptom)),
    updateUserMetrics: metric => dispatch(actions.updateUserMetrics(metric)),
    updateUserMetricUOM: uom => dispatch(actions.updateUserMetricUOM(uom)),
    updateDashboardGraphSuccess: data =>
      dispatch(actions.updateDashboardGraphSuccess(data)),
    onSelectGraphItem: graphId => dispatch(actions.selectGraphItem(graphId)),
    onUpdateJournalEntryType: journalEntryType =>
      dispatch(actions.updateJournalEntryTypeSuccess(journalEntryType)),
    fetchUserAttachments: artifactId =>
      dispatch(actions.fetchUserAttachments(artifactId)),
    setActiveTab: activeTabIndex =>
      dispatch(actions.setActiveTab(activeTabIndex)),
    onSaveHealthQuestion: (
      healthQuestion,
      artifactId,
      callBack,
      journalUserId,
    ) =>
      dispatch(
        actions.saveHealthQuestion(
          healthQuestion,
          artifactId,
          callBack,
          journalUserId,
        ),
      ),
    updateTemporyQuestionObject: questionObject =>
      dispatch(actions.updateTemporyQuestionObject(questionObject)),
    onTouchRoutine: flag =>
      dispatch(actions.onTouchRoutine(flag)),
    commonErrorHandler: (error) => dispatch(actions.commonErrorHandler(error)),
    updateTemporyEntryObject: entryObject =>
      dispatch(actions.updateTemporyEntryObject(entryObject)),
    setFinshedSchedules: data =>
      dispatch(actions.setFinshedSchedules(data)),
    onHideShowGraph: value => dispatch(actions.onHideShowGraph(value)),
    pullSchedulars: journalUserId => dispatch(actions.pullSchedulars(journalUserId)),
    getOverdueAddhocEntry: (entryId, entryTypeId, dayNumber, journalUserId, callBack) =>
      dispatch(actions.getOverdueAddhocEntry(entryId, entryTypeId, dayNumber, journalUserId, callBack)),
    resetDuplicates: () =>
      dispatch(actions.resetDuplicates()),
    updateContact: (addNewContact, contacts, callBack, journalUserId, fetchAttachments) =>
      dispatch(actions.updateContact(addNewContact, contacts, callBack, journalUserId, fetchAttachments)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { withRef: true },
)(AddRoutineDetails);
