import React, {useRef} from 'react';
import { View, Text, TextInput, TouchableOpacity, Image, Keyboard } from 'react-native';
import styles from '../../../../Css';
import ActivityIndicator from "../../../../components/ActivityIndicator/activityIndicator";
import stylesResponsive from "../../../../CssResponsive";
import {USERNAME_SAVE} from "../../../../screens/PeerToPeer/MainSection/Profile/Constraints";
const EditableInput = (props) => {
    const { styles: stylesRes, ids } = stylesResponsive.getProcessedStyles();
    const { 
        usernameExist, 
        userName, 
        onUsernameChange, 
        invalidUsername, 
        onBlurUsername,
        usernameEditable, 
        editPenVisible, 
        handleEditUsername, 
        removeIcon, 
        handleClearUsername, 
        profile,
        placeholder,  
        maxLength, 
        enableUserNameSave, 
        enableUserNameSaveCancel,
        handleCancelUserName, 
        handleOnSaveProfile, 
        saveWait, 
        editPenDisabled, 
        emptyUserName,
        userNameTaken,
        lockFeature
    } = props;
    const inputRef = useRef(null);

    const handleCancelUserNameTrigger = () => {
        Keyboard.dismiss();
        handleCancelUserName()
    }

    const handleEdit = () => {
        inputRef.current.focus();
        handleEditUsername();
    }
      
    return (
        <View style={[styles.inputfeildWidth, styles.userNameContainer, stylesRes.userNameRes]} dataSet={{ media: ids.userNameRes }}>
            <View>
                <View style={[styles.inputfeildWidth, styles.flexBetweenCenter]}>
                    <View style={[
                        styles.inputfeildWidth, 
                        styles.flexBetweenCenter, 
                        styles.profileUsernameInput,
                         usernameEditable && !lockFeature ? styles.blueBorderProfile : styles.validBorderProfile, 
                         (invalidUsername || userNameTaken) && styles.inValidColor, stylesRes.paddingCustomnRight]} 
                         dataSet={{ media: ids.paddingCustomnRight }}>
                        <TextInput
                            style={{ width: '100%' }}
                            allowFontScaling={false}
                            underlineColorAndroid="transparent"
                            value={userName ? userName : ''}
                            onChangeText={onUsernameChange}
                            onBlur={onBlurUsername}
                            autoFocus={!profile ? true : false}
                            placeholder={placeholder}
                            onFocus={ () => {
                                !Boolean(lockFeature) && handleEdit();
                            }}
                            maxLength={maxLength}
                            editable={!lockFeature}
                            ref={inputRef}
                        />

                        <View style={[styles.autoCompleteBtnView]}>
                            {
                            Boolean(editPenVisible)  ? 
                            (
                                <TouchableOpacity style={[styles.btnActionTouch]} onPress={handleEdit} disabled={editPenDisabled || lockFeature} >
                                    <View style={[styles.autoCompleteIconView]}>
                                        <Image
                                            style={styles.autoCompleteClearIcon}
                                            source={require('../../../../assets/icons/editpen.png')}
                                        />
                                    </View>
                                </TouchableOpacity>
                            ) : Boolean(removeIcon) ? (
                                <TouchableOpacity
                                    onPress={handleClearUsername}
                                    style={[styles.btnActionTouch]}
                                >
                                    <View style={[styles.autoCompleteIconView]}>
                                        <Image
                                            style={styles.autoCompleteClearIcon}
                                            source={require('../../../../assets/icons/clear.png')}
                                        />
                                    </View>
                                </TouchableOpacity>
                            ) : null}
                        </View>
                    </View>
                </View>
            </View>
            <View style={[styles.flexBetweenCenter, styles.flexWrapView, stylesRes.flexendProfile]} dataSet={{ media: ids.flexendProfile}} >
                <View style={styles.flexRowAiCt}>
                    {(invalidUsername || userNameTaken) ? 
                    (
                        <Image

                            style={styles.availableIconProfile}
                            source={require('../../../../assets/icons/unavailble.png')}
                        />
                    ): usernameExist ?
                    ( <Image
                        style={styles.availableIconProfile}
                        source={require('../../../../assets/icons/available.png')}
                    />) : null
                    }
                    <Text
                        style={[
                            styles.inputUnderTextTwo,
                            stylesRes.mobileTextThree
                        ]}
                        allowFontScaling={false}
                        dataSet={{ media: ids.mobileTextThree }}
                    >
                    { 
                    invalidUsername && emptyUserName ? 
                    'Username cannot be blank' : 
                    invalidUsername ?
                    'Max 20 characters (symbols or characters)' :
                    userNameTaken ? 
                    'That username is taken, Try another.' :
                    usernameExist? 
                    'Username Available' :
                    'Max 20 characters (symbols or characters)'
                    }
                    </Text>
                </View>

                {enableUserNameSaveCancel && (
                    <View style={[styles.flexRowAiCt, styles.flexJcFe, stylesRes.bottomRes,]} dataSet={{ media: ids.bottomRes }}>
                        <TouchableOpacity style={[styles.cancelProfile, styles.flexJcCtAiCt]} onPress={handleCancelUserNameTrigger}><Text style={styles.cancelText}>Cancel</Text></TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => handleOnSaveProfile(USERNAME_SAVE)}
                            disabled={!enableUserNameSave}
                            style={[
                                styles.saveProfile,
                                styles.flexJcCtAiCt,
                                styles.submitBgBlueDark,
                                !Boolean(enableUserNameSave)
                                    ? styles.btnBlurState
                                    : styles.btnActiveState,
                            ]}>
                            {Boolean(saveWait) && (
                                <View style={styles.actIndCom}>
                                    <ActivityIndicator size="small" />
                                </View>
                            )}
                            <Text style={styles.saveTextProfile}>Save</Text>
                        </TouchableOpacity>
                    </View>
                )}
            </View>
        </View>
    );
};



export default EditableInput;
