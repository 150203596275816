import {Platform} from 'react-native';

const PRODUCT_IDS_FOR_ENVIRONMENT = {
  QA: {
    WEEKLY: 'ZOEINSIGHTSPREMIUMPLANWEEKLYQA', // Debugging only plan
    MONTHLY: 'ZOEINSIGHTSPREMIUMPLANMONTHLYQA',
    ANNUAL: 'ZOEINSIGHTSPREMIUMPLANANNUALQA',
  },
  UAT: {
    MONTHLY: 'ZOEINSIGHTSPREMIUMPLANMONTHLY',
    ANNUAL: 'ZOEINSIGHTSPREMIUMPLANANNUAL',
  },
  PROD: {
    MONTHLY: '', // TODO:: Add the Production app's Product ID for Monthly Plan here
    ANNUAL: '', // TODO:: Add the Production app's Product ID for Annual Plan here
  },
};

// App Specific passwords used for IAP Receipt Validation
// The values are used only for receipt validation during development
// Receipt validation is not to be done in the app in production
const APP_SPECIFIC_PASSWORDS_FOR_ENVIRONMENT = {
  QA: 'b0595dd5f59a4c5886e644c01e9173c8',
  UAT: '93711ac74c9144a594c5c787ba8a3305',
};

// ====================================================================================
// ======= CHANGE FOLLOWING VALUES ACCORDING TO DEPLOYMENT TARGET ENVIRONMENT =========

// App specific password for deployment
// TODO:: Change the value for correct deployment (Only for QA or UAT during development)
export const APP_SPECIFIC_PASSWORD = APP_SPECIFIC_PASSWORDS_FOR_ENVIRONMENT.UAT;

// Product IDs for deployment
// TODO:: Change the value for correct deployment
export const SUBSCRIPTION_PRODUCT_IDS = PRODUCT_IDS_FOR_ENVIRONMENT.UAT;

// Product IDs map for RNIap
// NOTE:: It will pick the correct values based on the value set for PRODUCT_IDS
export const SUBSCRIPTION_PRODUCT_IDS_MAP_RNIAP = Platform.select({
  ios: [SUBSCRIPTION_PRODUCT_IDS.MONTHLY, SUBSCRIPTION_PRODUCT_IDS.ANNUAL],
  android: [],
});

// Is IAP debugging in progress?
// TODO:: Change it to false if deploying to UAT/PROD
export const IS_DEBUGGING = false;
export const DEBUGGING_SUBSCRIPTION_DURATION = {
  WEEKLY: 3 * 60 * 1000, // WEEK  ==> 3 minutes
  MONTHLY: 5 * 60 * 1000, // MONTH ==> 5 minutes
  ANNUAL: 60 * 60 * 1000, // YEAR  ==> 60 minutes
};

// ====================================================================================

// Updating user purchase details update status
export const IOS_IAP_USER_PURCHASE_INFO_STATUS = {
  UPDATED: 'updated',
  PENDING: 'pending',
};

export const IOS_IAP_USER_PURCHASE_INFO_KEY = '@iOSIapUserPurchaseInfo';
