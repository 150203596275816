import React from 'react';
import {
  View,
  Text,
  Image,
  TextInput,
  Platform,
} from 'react-native';
import stylesResponsive from '../../CssResponsive';
import styles from '../../Css';
import UIText from '../../components/Text/text';
import UITextInput from '../../components/TextInput/textInput';
import FileUpload from './FileUpload/FileUpload';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import PrimaryButton from '../../components/Button/primaryButton';
import PrimaryButtonWhite from '../../components/Button/primaryButtonWhite';
import CloseButton from '../../components/Button/closeButton';
import KeyboardAwareScrollViewHoc from '../../components/KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc';
import { pendoTrackEvent } from '../../util/pendoConfig';
import AddEventTags from '../../components/Tags/EventTags';
import CommonStuff from './CommonStuff';
import { ANDROID, WEB } from '../../util/platformWindowConfig';
import { unFocus } from 'zamplo-common-js';

class ResourceModal extends CommonStuff {
  constructor(props) {
    super(props);
    this.state = {
      url: '',
      isModalVisible: false,
      isLoading: false,
      numberOfUsers: 0,
      title: '',
      file: null,
      validUrl: true,
      validTitle: true,
      tagsArray: [],
      description: '',
      exist: false,
      error: false,
      errorDescription: '',
      saveWait: false,
      resourceId: null,
      disabled: false,
      editableUrl: true,
      fileChecksum: null,
      editable: true,
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth,
      saveDisabled: true,
      fileUploadWait: false,
      selection: {start: 0, end: 0},
      selectionTitle: {start: 0, end: 0},
      width: '100%',
      marginBottom: 30,
      showList : true,  
    };
    this.scrollViewRef = React.createRef();
    this.dimentions = {}
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (this.state.url && nextProps.validateResource) {
      if (
        nextProps.validateResource.membersUsingThisURL &&
        nextProps.validateResource.membersUsingThisURL !== 0
      ) {
        this.setState({
          exist: true,
          error: true,
          errorDescription: 'Resource already exists.',
          saveDisabled: false
        });
      } else {
        this.setState({
          exist: false,
          error: false,
          errorDescription: '',
          saveDisabled: false
        });
      }
      this.setState({
        numberOfUsers: nextProps.validateResource.membersUsingThisURL
          ? nextProps.validateResource.membersUsingThisURL
          : 0,
        title: nextProps.validateResource.title
          ? nextProps.validateResource.title
          : '',
        validTitle: nextProps.validateResource.title ? true : false,
        url: nextProps.validateResource.url
          ? nextProps.validateResource.url
          : '',
        validUrl: nextProps.validateResource.url ? true : false,
        resourceId: nextProps.validateResource.resourceId
          ? nextProps.validateResource.resourceId
          : null,
      });
    } else if (this.state.file && nextProps.validateDocumentResource) {
      if (
        nextProps.validateDocumentResource.count &&
        nextProps.validateDocumentResource.count !== 0
      ) {
        this.setState({
          exist: true,
          error: true,
          errorDescription: 'Resource already exists.',
          numberOfUsers: nextProps.validateDocumentResource.count
            ? nextProps.validateDocumentResource.count
            : 0,
          resourceId: nextProps.validateDocumentResource.resourceId
            ? nextProps.validateDocumentResource.resourceId
            : null,
          saveDisabled: false
        });
      } else {
        this.setState({
          exist: false,
          error: false,
          errorDescription: '',
          saveDisabled: false
        });
      }
    }
    const {windowHeight, windowWidth} = nextProps;
    this.setState({
      windowWidth,
      windowHeight,
    });
  };

  handleUrlInputBlur = () => {
    const {url, validUrl} = this.state;
    const {onResourceValidate} = this.props;
    if (url && validUrl && onResourceValidate) {
      this.setState({
        disabled: true,
        selection: {start: 0, end: 0},
      });
      onResourceValidate(url, (success, res) => {
        console.log('res?.message',res?.message, res);
        if (!success) {
          this.setState({
            error: true,
            errorDescription: !res || res?.message === "Endpoint request timed out" ? 'Invalid resource': res?.message? res?.message :  res,
            validUrl: false,
            saveDisabled: true
          });
        } else {
          this.setState({
            error: false,
            errorDescription: '',
            validUrl: true,
            saveDisabled: false
          });
        }
      });
    }
  };

  handleDeleteFile = () => {
    this.setState({
      file: null,
      editableUrl: true,
      validUrl: true,
      fileChecksum: null,
      url: null,
      exist: false,
      error: false,
      errorDescription: '',
      numberOfUsers: 0,
      resourceId: null,
      title: '',
      saveDisabled: true
    });
  };

  handleOnSaveResource = () => {
    if (!this.state.url && !this.state.file) {
      this.setState({validUrl: false});
      return;
    } else if (!this.state.title) {
      this.setState({validTitle: false});
      return;
    } else {
      this.setState({validTitle: true, validUrl: true});
    }
    this.setState({saveWait: true, saveDisabled: true});
    let addCommunity = {};
    let tagBox = [];
    this.state.tagsArray.forEach(res => {
      if (res.tags) {
        tagBox.push(res.tags);
      }
    });
    if (this.state.url) {
      addCommunity = {
        // fileName: "",
        // fileChecksum: "",
        title: this.state.title,
        description: this.state.description,
        userId: this.props.journalUserId
          ? this.props.journalUserId.toString()
          : this.props.user.id.toString(),
        url: this.state.url,
        ...(tagBox && tagBox.length > 0 && {tags: tagBox}),
        publishedBy : false,
      };
    } else {
      addCommunity = {
        fileName: this.state.file.name,
        fileChecksum: this.state.fileChecksum,
        title: this.state.title,
        description: this.state.description,
        userId: this.props.journalUserId
          ? this.props.journalUserId.toString()
          : this.props.user.id.toString(),
        ...(tagBox && tagBox.length > 0 && {tags: tagBox}),
        publishedBy : false,
      };
    }

    pendoTrackEvent('HealthLibrary@ > Save');
    this.props.addCommunityGroupsResources(addCommunity, res => {
      if (res && res === 'Successfully saved resource') {
        this.setState(
          {
            url: '',
            numberOfUsers: 0,
            title: '',
            file: null,
            validUrl: true,
            description: '',
            exist: false,
            error: false,
            errorDescription: '',
            validTitle: true,
            saveWait: false,
            saveDisabled: false,
            disabled: false,
            editableUrl: true,
            editable: true,
            tagsArray: [],
          },
          () => {
            this.props.close(true, res);
            this.props.clearAddedResource();
          },
        );
      } else if (res && res.s3PutObjectURL) {
        // RNFetchBlob.fetch('PUT', res.s3PutObjectURL, {}, RNFetchBlob.wrap((this.state.file.uri)).then(res =>{

        // });
        this.handleUpload(res.s3PutObjectURL, () => {
          this.setState(
            {
              url: '',
              numberOfUsers: 0,
              title: '',
              file: null,
              validUrl: true,
              description: '',
              exist: false,
              error: false,
              errorDescription: '',
              validTitle: true,
              saveWait: false,
              saveDisabled: false,
              disabled: false,
              editableUrl: true,
              editable: true,
              tagsArray: [],
            },
            () => {
              this.props.close(true, 'Successfully saved resource');
              this.props.clearAddedResource();
            },
          );
        });
      } else if (res && res === 'Caregiver has permission type: READ_ONLY') {
        this.addResourceCancel();
      } else if (res && res === 'unknown url type: \'' + this.state.url + '\'') {
        this.setState({
          validUrl: false,
          exist: false,
          error: true,
          errorDescription: res,
          saveWait: false,
          saveDisabled: false,
        });
      } else {
        if (res) {
          if (res.count !== 0) {
            this.setState({
              exist: true,
              error: true,
              errorDescription: 'Resource already exists.',
              saveWait: false,
              saveDisabled: false,
            });
          } else {
            this.setState({
              exist: false,
              error: false,
              errorDescription: '',
              saveWait: false,
              saveDisabled: false,
            });
          }
          this.setState({
            numberOfUsers: res.count ? res.count : 0,
            validUrl: res.url ? true : false,
            resourceId: res.resourceId ? res.resourceId : null,
            saveWait: false,
            saveDisabled: false,
          });
        } else {
          this.setState({
            saveWait: false,
            saveDisabled: false,
          });
        }

        this.setState({
          exist: true,
          saveWait: false,
          saveDisabled: false,
          // numberOfUsers: res.count
        });
      }
    });
  };

  handleOnReplaceResource = () => {
    if (!this.state.url && !this.state.file) {
      this.setState({validUrl: false});
      return;
    } else if (!this.state.title) {
      this.setState({validTitle: false});
      return;
    } else {
      this.setState({validTitle: true, validUrl: true});
    }
    this.setState({saveWait: true, saveDisabled: true});
    let tagBox = [];
    this.state.tagsArray.forEach(res => {
      tagBox.push(res.tags);
    });
    let replaceResource = {
      resourceId: this.state.resourceId
        ? this.state.resourceId.toString()
        : null,
      Description: this.state.description,
      title: this.state.title,
      userId: this.props.journalUserId
        ? this.props.journalUserId.toString()
        : this.props.user.id.toString(),
      ...(tagBox && tagBox.length > 0 && {tags: tagBox}),
    };
    this.props.addReplaceMyList(replaceResource, res => {
      if (
        res === 'Resource is already added to the list' ||
        res === 'Successfully saved resource' ||
        res === 'Resource updated' ||
        /people added this resource/i.test(res)
      ) {
        this.setState(
          {
            url: '',
            numberOfUsers: 0,
            title: '',
            file: null,
            validUrl: true,
            description: '',
            exist: false,
            error: false,
            errorDescription: '',
            validTitle: true,
            saveWait: false,
            saveDisabled: false,
            disabled: false,
            editableUrl: true,
            editable: true,
            tagsArray: [],
          },
          () => {
            this.props.close(true, res);
            this.props.clearAddedResource();
          },
        );
      } else {
      }

      this.setState({saveWait: false, saveDisabled: false});
    });
  };

  addResourceCancel = () => {
    this.setState(
      {
        url: '',
        numberOfUsers: 0,
        title: '',
        file: null,
        validUrl: true,
        description: '',
        exist: false,
        error: false,
        errorDescription: '',
        validTitle: true,
        saveWait: false,
        saveDisabled: false,
        disabled: false,
        editableUrl: true,
        editable: true,
        tagsArray: [],
      },
      () => {
        this.props.close(false, '');
        this.props.clearAddedResource();
      },
    );
  };

  setIsFocused = async (flag, textInputRef) => {
    if (!flag) {
      Platform.OS === WEB
        ? this.scrollViewRef.current?.scrollTo({x: 0, y: this.dimentions?.scroll?.y, animated: true})
        : this.scrollViewRef.current?.scrollToPosition(0, this.dimentions?.scroll?.y, true);
      await new Promise(r => setTimeout(r, 300))
      this.setState({marginBottom: 30})
      return
    }
    if (this.scrollViewRef.current) {
      this.setState({marginBottom: this.dimentions?.scroll?.height || 30}, () => {
        setTimeout(() => {
          if (this.dimentions?.tag) {
            Platform.OS === WEB
              ? this.scrollViewRef.current.scrollTo({x: 0, y: (this.dimentions?.tag?.y + (this.dimentions?.tag?.height - this.dimentions?.tagEnd?.height)) - (this.dimentions?.scroll?.height / 2), animated: true})
              : this.scrollViewRef.current.scrollToPosition(0, (this.dimentions?.tag?.y + (this.dimentions?.tag?.height - this.dimentions?.tagEnd?.height)) - (this.dimentions?.scroll?.height / 2), true)
          }
          setTimeout(() => {
            textInputRef?.focus?.()
          }, 100);
        }, 100);
      })
    }
  }

  dismissTagList = () => {
    this.setState({showList : false})
    setTimeout(()=>{
      this.setState({showList: true, marginBottom : 30})
      Platform.OS === WEB
      ? this.scrollViewRef.current?.scrollTo({x: 0, y: 50, animated: true})
      : this.scrollViewRef.current?.scrollToPosition(0, 50, true);
    },100)
  }

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const {hasNotch} = this.props;
    let fullWidth = this.state.windowWidth;
    let modalMaxWidth = 400;
    if (fullWidth > 574) {
      modalMaxWidth = 375;
    } else if (fullWidth > 374) {
      modalMaxWidth = 340;
    } else {
      modalMaxWidth = 300;
    }
    let descriptionText = this.state.description;
    return (
      <React.Fragment>
        <KeyboardAwareScrollViewHoc
          ref={this.scrollViewRef}
          onLayout={e => this.dimentions = {...this.dimentions, scroll: e.nativeEvent.layout}}
          hasNotch={hasNotch}
          customStyle={{backgroundColor: "white", flexGrow: 0}}
         >
        <View 
          style={[stylesRes.resourceModalScrollWrapperCom, {width: modalMaxWidth}]}
          dataSet={{media: ids.resourceModalScrollWrapperCom}}
          onStartShouldSetResponder={unFocus}>
            <View style={[styles.popUpBlock, styles.bgWhite, styles.popUpRad]}>
              <View style={styles.popUpContentArea}>
                <View style={styles.gridSectionPopup}>
                  <View style={[styles.colPdTopBotLg]}>
                    <CloseButton touchableOnPress={this.addResourceCancel} />
                    <View>
                      <View style={styles.gridRow}>
                        <View style={styles.gridColFull}>
                          <View style={[styles.colPdLtRt]}>
                            <View style={[stylesRes.infoBlockMax]}>
                              <View
                                style={[
                                  styles.infoBlock,
                                  styles.infoPd,
                                  styles.pdTopSm,
                                ]}>
                                <Text
                                  style={[
                                    styles.textTitleMdBold,
                                    styles.textAlignCenter,
                                  ]}
                                  allowFontScaling={false}
                                  >
                                  Add New Resource
                                </Text>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>

                      <View style={styles.gridRow}>
                        <View style={styles.gridColFull}>
                          <View style={[styles.colPdLtRt]}>
                            <View
                              style={[styles.fieldRow, styles.popupTitleMg]}>
                              <View style={[styles.fieldCol]}>
                                <UITextInput
                                  style={[
                                    styles.textField,
                                    styles.textFieldSm,
                                    this.state.validUrl
                                      ? styles.validColor
                                      : styles.inValidColor,
                                  ]}
                                  onChangeText={value => {
                                    let re = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
                                    if (value && re.test(value)) {
                                      this.setState({
                                        url: value,
                                        validUrl: re.test(value),
                                        exist: false,
                                        error: false,
                                        numberOfUsers: 0,
                                        disabled: true,
                                      });
                                    } else if (value) {
                                      this.setState({
                                        url: value,
                                        validUrl: false,
                                        exist: false,
                                        error: false,
                                        numberOfUsers: 0,
                                        disabled: true,
                                      });
                                    } else {
                                      this.setState({
                                        url: null,
                                        validUrl: false,
                                        exist: false,
                                        error: false,
                                        numberOfUsers: 0,
                                        disabled: false,
                                        saveDisabled: true
                                      });
                                    }
                                  }}
                                  editable={this.state.editableUrl}
                                  value={this.state.url}
                                  placeholder="Enter URL"
                                  onBlur={this.handleUrlInputBlur}
                                  autoCapitalize={'none'}
                                  onFocus={() => {
                                    Platform.OS === ANDROID &&
                                    this.setState(
                                      {
                                        selection: {
                                          start: this.state.url
                                            ? this.state.url.length
                                            : 0,
                                          end: this.state.url
                                            ? this.state.url.length
                                            : 0,
                                        },
                                      },
                                      () => {
                                        this.setState({selection: null});
                                      },
                                    );
                                    this.dismissTagList();
                                  }}
                                  selection={Platform.OS !== ANDROID ? undefined : this.state.selection}
                                />
                              </View>
                              <View
                                style={[
                                  styles.fieldDataDesc,
                                  styles.pdTopExSm,
                                ]}>
                                {(this.state.url || this.state.file) &&
                                this.state.numberOfUsers !== 0 ? (
                                  <Text
                                    style={[
                                      styles.textMicSm,
                                      styles.textAlignCenter,
                                      styles.textEm,
                                    ]}
                                    allowFontScaling={false}
                                    >
                                    {`${
                                      this.state.numberOfUsers
                                    } users have this resource`}
                                  </Text>
                                ) : null}
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                      <View style={styles.gridRow}>
                        <View style={styles.gridColFull}>
                          <View style={[styles.colPdLtRt]}>
                            <View style={[styles.fieldRow, styles.noMgBot]}>
                              <View style={[styles.fieldCol]}>
                                <UITextInput
                                  style={[
                                    styles.textField,
                                    styles.textFieldSm,
                                    this.state.validTitle
                                      ? styles.validColor
                                      : styles.inValidColor,
                                  ]}
                                  onChangeText={value =>
                                    this.setState({
                                      title: value,
                                      validTitle: true,
                                    })
                                  }
                                  value={this.state.title}
                                  placeholder={'Enter title'}
                                  numberOfUsers={this.state.numberOfUsers}
                                  onBlur={() => {
                                    Platform.OS === ANDROID &&
                                    this.setState({
                                      selectionTitle: {
                                        start: 0,
                                        end: 0,
                                      },
                                    });
                                  }}
                                  onFocus={() => {
                                    Platform.OS === ANDROID &&
                                    this.setState(
                                      {
                                        selectionTitle: {
                                          start: this.state.title.length,
                                          end: this.state.title.length,
                                        },
                                      },
                                      () => {
                                        this.setState({
                                          selectionTitle: null,
                                        });
                                      },
                                    );
                                    this.dismissTagList();
                                  }}
                                  selection={Platform.OS !== ANDROID ? undefined : this.state.selectionTitle}
                                />
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>

                      <View style={styles.gridRow}>
                        <View style={styles.gridColFull}>
                          <View style={[styles.colPdLtRt]}>
                            <View style={stylesRes.infoBlockMax}>
                              <View
                                style={[
                                  styles.infoBlock,
                                  styles.infoPdTopBotExSm,
                                ]}>
                                <Text
                                  style={[
                                    styles.textTitleMd,
                                    styles.textAlignCenter,
                                  ]}
                                  allowFontScaling={false}
                                  >
                                  OR
                                </Text>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>

                      <FileUpload
                        onFileAdd={this.handleFileAdd}
                        file={this.state.file}
                        handleDeleteFile={this.handleDeleteFile}
                        disabled={this.state.disabled}
                        nativeId={'healthLibraryUploadFile'}
                        fileUploadWait={this.state.fileUploadWait}
                      />

                      <View style={styles.gridRow} onLayout={e => this.dimentions = {...this.dimentions, desc: e.nativeEvent.layout}}>
                        <View style={styles.gridColFull}>
                          <View style={[styles.colPdLtRt]}>
                            <View
                              style={[
                                styles.fieldRow,
                                styles.comMgTop,
                                styles.popupTitleMg,
                              ]}>
                              <View style={[styles.fieldCol]}>
                                <TextInput
                                  style={[styles.textAreaSm,
                                    Boolean(descriptionText?.length > 160)
                                    ? styles.inValidColor
                                    : styles.validColor
                                  ]}
                                  allowFontScaling={false}
                                  value={this.state.description}
                                  onChangeText={value => {
                                    let exceedMaxLenght = Boolean(value.length > 160);
                                    descriptionText = value;
                                    this.setState({
                                      description: value,
                                      error: exceedMaxLenght,
                                      errorDescription: exceedMaxLenght ? 'Max 160 characters for description' : ''
                                    });
                                  }}
                                  placeholder={
                                    'Enter a description of the resource'
                                  }
                                  onFocus={() => this.dismissTagList()}
                                  returnKeyType={'next'}
                                  multiline={true}
                                  underlineColorAndroid="transparent"
                                  onLayout={e => this.setState({width: e.nativeEvent.layout.width})}
                                />
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                      <View style={styles.gridRow} onLayout={e => this.dimentions = {...this.dimentions, tag: e.nativeEvent.layout}}>
                        <View style={styles.gridColFull}>
                          <View style={[styles.colPdLtRt, styles.pdTopExSm]}>
                            <View style={styles.fieldRow}>
                              {Boolean(this.state.showList) && 
                              <AddEventTags 
                                windowWidth={this.props.windowWidth}
                                updateTagArray={(tagsArray) => this.setState({tagsArray: tagsArray})}
                                tagsArray={this.state.tagsArray}
                                hideIcon
                                customStyle={{
                                  paddingTop: 0,
                                  paddingBottom: 10,
                                  borderRadius: 5,
                                  borderColor: "#C4C4C4"
                                }}
                                customWidth={this.state.width}
                                fetchHealthJourneyTagsList={this.props.fetchHealthJourneyTagsList}
                                setIsFocused={this.setIsFocused}
                                tagLimit={Infinity}
                                healthLibrary
                              /> 
                            }
                              <View style={{height: this.state.marginBottom, zIndex: -9999}}  onLayout={e => this.dimentions = {...this.dimentions, tagEnd: e.nativeEvent.layout}}></View>
                            </View>
                          </View>
                        </View>
                      </View>
                      <View style={styles.gridRow}>
                        <View style={styles.gridColFull}>
                          {Boolean(this.state.error) && (
                            <View
                              style={[styles.notifyBlock, styles.colPdLtRt]}>
                              <View style={styles.notifyDetails}>
                                <View style={styles.notifyWarning}>
                                  <Image
                                    style={styles.iconWarning}
                                    source={require('../../assets/icons/warning.png')}
                                  />
                                </View>
                                <UIText
                                  style={styles.notifyText}
                                  title={this.state.errorDescription}
                                />
                              </View>
                            </View>
                          )}
                        </View>
                      </View>

                      <View style={[styles.gridRow, {zIndex: -99999}]}>
                        <View style={styles.gridColFull}>
                          <View style={[styles.colPdLtRt]}>
                            <View
                              style={[
                                styles.btnArea,
                                styles.flexRow,
                                styles.topPdExSm,
                                styles.infoPdBotSm,
                              ]}>
                              <View style={styles.fieldBtn}>
                                <PrimaryButtonWhite
                                  btnText={'Cancel'}
                                  btnPosition={'full'}
                                  btnStyle={[styles.darkBtnBorder]}
                                  touchableOnPress={() => {
                                    this.addResourceCancel();
                                    pendoTrackEvent('HealthLibrary@ > Cancel');
                                  }}
                                  btnTextStyle={styles.textColorGreyLight}
                                  activityIndStyle={styles.indicatorLtDefaultSm}
                                  disabled={this.state.saveWait}
                                  nativeId={'healthLibraryCancel'}
                                  buttonHeigtSm={true}
                                />
                              </View>
                              <View style={styles.fieldBtnSpSm} />
                              <View style={styles.fieldBtn}>
                              {this.state.exist ? (
                                  <PrimaryButton
                                    btnText={'Replace'}
                                    btnPosition={'full'}
                                    touchableOnPress={this.handleOnReplaceResource}
                                    activityInd={this.state.saveWait}
                                    btnShadow={true}
                                    disabled={this.state.saveDisabled || this.state.fileUploadWait || Boolean(this.state.description.length > 160)}
                                  />
                                ) : (
                                  <PrimaryButton
                                    btnText={'Save'}
                                    btnPosition={'full'}
                                    touchableOnPress={
                                      this.handleOnSaveResource
                                    }
                                    activityInd={this.state.saveWait}
                                    btnShadow={true}
                                    disabled={this.state.saveDisabled || this.state.fileUploadWait || Boolean(this.state.description.length > 160)}
                                    nativeId={'healthLibrarySave'}
                                  />
                                )}
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
        </View>
        </KeyboardAwareScrollViewHoc>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onResourceValidate: (url, callBack) =>
      dispatch(actions.validateResource(url, callBack)),
    addCommunityGroupsResources: (addCommunity, callBack) =>
      dispatch(actions.addCommunityGroupsResources(addCommunity, callBack)),
    clearAddedResource: () => dispatch(actions.resetAddResourceFetchedData()),
    addReplaceMyList: (replaceResource, callBack) =>
      dispatch(actions.addReplaceMyList(replaceResource, callBack)),
    onResourceDocumentValidation: fileObject =>
      dispatch(actions.onResourceDocumentValidation(fileObject)),
    resourceFileUpload: (s3Url, file, callBack) => {
      dispatch(actions.resourceFileUpload(s3Url, file, callBack));
    },
    fetchHealthJourneyTagsList: (filterKey, serviceType, callback) =>
      dispatch(actions.fetchHealthJourneyTagsList(filterKey, serviceType, callback)),
  };
};

const mapStateToProps = state => {
  return {
    validateResource: state.communityGroups.validateResource,
    validateDocumentResource: state.communityGroups.validateDocumentResource,
    user: state.routines.user,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    journalUserId: state.uistate.journalUserId,
    hasNotch: state.uistate.hasNotch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResourceModal);
