import React, {Component} from 'react';
import {Platform} from 'react-native';
import EditProfile from '../../components/Profile/editProfile';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import moment from 'moment';
import * as validator from '../../validator/validator';
import {PEER_TO_PEER, PROFILE} from '../../navigation/path';
import * as url from '../../store/actions/urls';
import * as axiosCommon from '../../axios/axios-common';
import {logout} from '../../store/actions/index';
import KeychainWrapper from '../../components/Biometric/keyChain';
import {hasValue} from '../../util/hashValueGenerator';
import SavingConfirmation from '../../components/ConfirmationBox/savingConfirmation';
import {profileTitle, alertBody} from '../../GlobalFunctions/GlobalString';
import { pendoTrackEvent } from '../../util/pendoConfig';
import { chartTypes } from '../../Constant/index';
import { WEB } from '../../util/platformWindowConfig';


class EditProfileScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: (props.user || {}).id,
      dob: props.user.birthday
        ? moment.utc(props.user.birthday, 'x').format('YYYY-MM-DD')
        : null,
      gender: (props.user || {}).sex,
      height: null,
      weight: null,
      emergencyContact: null,
      visibleCalModal: false,
      showSetDate: null,
      weightUomSymbol: null,
      heightUomSymbol: null,
      ethnicity: (props.user || {}).ethnicity,
      city: (props.user || {}).city,
      state: props.user.state ? props.user.state : '',
      country: props.user.country ? props.user.country : '',
      saveWait: false,
      invalidDay: false,
      invalidMonth: false,
      invalidYear: false,
      firstName: this.props.user.firstName ? this.props.user.firstName : '',
      lastName: this.props.user.lastName ? this.props.user.lastName : '',
      email: this.props.user.email ? this.props.user.email : '',
      emailValidate: true,
      validFirstName: true,
      validLastName: true,
      loading: true,
      emailIsChanged: false,
      confirmBox: false,
      hashValue: null,
      conditionsToDelete: [],
      initConditions: [],
      newUserAllergies: props.userAllergies,
      userAllergiesTobeDeleted: [],
      fromCommiunityProfile: props.route?.params?.from === PEER_TO_PEER.path,

    };
    this.scrollViewRef = null;
    this.invalidMedicalConditions = 0; //using to identify invalied condition fields count
    this.nameOrEmail = false; // using to identify name or email field validation
  }

  addUserAllergy = (allergyObj) => {
    this.setState(prevState => ({newUserAllergies: [...prevState.newUserAllergies, allergyObj]}));
  }

  deleteUserAllergy = (id) => {
    const updatedAllergies = this.state.newUserAllergies.filter(item => item.id !== id);
    if (id < 0) {
      this.setState({newUserAllergies: updatedAllergies});
    } else {
      this.setState(prevState => ({userAllergiesTobeDeleted: [...prevState.userAllergiesTobeDeleted, id], newUserAllergies: updatedAllergies}));
    }
  }

  componentDidMount() {

    this.getLetastJournal();
    setTimeout(
      () => this.setState({loading: false}, () => {
        this.setState({initConditions: Object.assign([], this.props.userConditions)});
        this.checkhash();
      }),
      1500,
    );
    this.getWeightUnits();
    this.getHeightUnits();

    this.props.navigation.addListener('focus', () => {
      this.props.hideMobileMenu();
      pendoTrackEvent('Profile/@EditProfile');
    });
    this.props.navigation.addListener('blur', () => {
      this.props.showMobileMenu();
    });
  }

  checkhash = () => {
    const {
      firstName,
      lastName,
      email,
      ethnicity,
      city,
      state,
      country,
      dob,
      gender,
      validated,
      weight,
      weightUomSymbol,
      height,
      heightUomSymbol,
    } = this.state;
    const {userConditions} = this.props;
    let temp = [];
    if (userConditions && userConditions.length > 0) {
      userConditions.forEach(res => {
        temp.push({name: res.name, year: res.year});
      });
    }
    //cond = temp;

    this.hasValue = hasValue(
      firstName +
        ',' +
        lastName +
        ',' +
        email +
        ',' +
        ethnicity +
        ',' +
        city +
        ',' +
        state +
        ',' +
        country +
        ',' +
        dob +
        ',' +
        gender +
        ',' +
        validated +
        ',' +
        weight +
        ',' +
        weightUomSymbol +
        ',' +
        height +
        ',' +
        heightUomSymbol +
        ',' +
        userConditions,
    );
    this.setState({hasValue: this.hasValue});
  };

  getHightMetrixUser = () =>
    this.props.metricsDigest.userDefinedMetrics.filter(function(res) {
      return res.name === 'Height';
    });

  getHightMetrixSystem = () =>
    this.props.metricsDigest.systemDefinedMetrics.filter(function(res) {
      return res.name === 'Height';
    });

  getWeightMetrixUser = () =>
    this.props.metricsDigest.userDefinedMetrics.filter(function(res) {
      return res.name === 'Weight';
    });

  getWeightMetrixSystem = () =>
    this.props.metricsDigest.systemDefinedMetrics.filter(function(res) {
      return res.name === 'Weight';
    });

  getLetastJournal = () => {
    const hightMetrixUser = this.getHightMetrixUser();

    const hightMetrixSystem = this.getHightMetrixSystem();

    const weightMetrixUser = this.getWeightMetrixUser();

    const weightMetrixSystem = this.getWeightMetrixSystem();

    let journalEntriesHeight = [];
    let journalEntriesWeight = [];
    const journalEntries = Object.values(this.props.journalEntriesProcessed);
    journalEntries.filter(function(res) {
      if (res && res.metrics) {
        res.metrics.filter(function(re) {
          if (
            hightMetrixUser &&
            hightMetrixUser.length > 0 &&
            re.metric === hightMetrixUser[0].id
          ) {
            journalEntriesHeight.push(res);
          } else if (
            hightMetrixSystem &&
            hightMetrixSystem.length > 0 &&
            re.metric === hightMetrixSystem[0].id
          ) {
            journalEntriesHeight.push(res);
          }
          if (
            weightMetrixUser &&
            weightMetrixUser.length > 0 &&
            re.metric === weightMetrixUser[0].id
          ) {
            journalEntriesWeight.push(res);
          } else if (
            weightMetrixSystem &&
            weightMetrixSystem.length > 0 &&
            re.metric === weightMetrixSystem[0].id
          ) {
            journalEntriesWeight.push(res);
          }
          return journalEntriesWeight && journalEntriesHeight;
        });
      }
      return journalEntriesHeight && journalEntriesWeight;
    });
    if (journalEntriesHeight && journalEntriesHeight.length > 0) {
      journalEntriesHeight.sort(function(a, b) {
        return b.modifiedOn - a.modifiedOn;
      });
      this.getHight(journalEntriesHeight[0]);
    }

    if (journalEntriesWeight && journalEntriesWeight.length > 0) {
      journalEntriesWeight.sort(function(a, b) {
        return b.modifiedOn - a.modifiedOn;
      });
      this.getWeight(journalEntriesWeight[0]);
    }
  };

  getHight = journal => {
    const hightMetrixUser = this.getHightMetrixUser();

    const hightMetrixSystem = this.getHightMetrixSystem();

    if (journal && journal.metrics) {
      let metrics = [];
      journal.metrics.filter(function(res) {
        if (
          hightMetrixUser &&
          hightMetrixUser.length > 0 &&
          res.metric === hightMetrixUser[0].id
        ) {
          metrics.push(res);
        } else if (
          hightMetrixSystem &&
          hightMetrixSystem.length > 0 &&
          res.metric === hightMetrixSystem[0].id
        ) {
          metrics.push(res);
        }
        return metrics;
      });
      if (metrics && metrics[0]) {
        this.setState({
          height: metrics[0].value ? metrics[0].value : null,
          heightUomSymbol:
            metrics[0].unitOfMeasure &&
            this.props.unitsOfMeasureDigestProcessed.unitsOfMeasure[
              metrics[0].unitOfMeasure
            ].symbol
              ? this.props.unitsOfMeasureDigestProcessed.unitsOfMeasure[
                  metrics[0].unitOfMeasure
                ].symbol
              : null,
        });
      }
    }
  };

  getWeight = journal => {
    const weightMetrixUser = this.getWeightMetrixUser();
    const weightMetrixSystem = this.getWeightMetrixSystem();
    if (journal && journal.metrics) {
      let metrics = [];
      journal.metrics.filter(function(res) {
        if (
          weightMetrixUser &&
          weightMetrixUser.length > 0 &&
          res.metric === weightMetrixUser[0].id
        ) {
          metrics.push(res);
        } else if (
          weightMetrixSystem &&
          weightMetrixSystem.length > 0 &&
          res.metric === weightMetrixSystem[0].id
        ) {
          metrics.push(res);
        }
        return metrics;
      });

      if (metrics && metrics[0]) {
        this.setState({
          weight: metrics[0].value ? metrics[0].value : null,
          weightUomSymbol:
            metrics[0].unitOfMeasure &&
            this.props.unitsOfMeasureDigestProcessed.unitsOfMeasure[
              metrics[0].unitOfMeasure
            ].symbol
              ? this.props.unitsOfMeasureDigestProcessed.unitsOfMeasure[
                  metrics[0].unitOfMeasure
                ].symbol
              : null,
        });
      }
    }
  };

  updateMetric = (name, typeOfMeasurement, weight) => {

    let userMetrics = null;
    let metricUOM = null;
    let createMetric = {
      metric: {
        id: -1,
        name: name,
        description: '',
        typeOfMeasurement: typeOfMeasurement,
        metricGroup: 1,
        displayProperties: {
          color: 'rgba(113, 168, 220, 1)',
          style: chartTypes.dash,
          scaleMin: null,
          scaleMax: null,
        },
      },
    };
    let CREATE_METRICS = url.CREATE_METRICS;
    let CREATE_UOM = url.CREATE_UOM;
    if (this.props.journalUserId) {
      CREATE_METRICS += '?journalUserId=' + this.props.journalUserId;
      CREATE_UOM += '?journalUserId=' + this.props.journalUserId;
    }

    axiosCommon.axiosPUT(
      CREATE_METRICS,
      res => {
        if (res.type === 'SUCCESS') {
          if (res.responseDto.data.metric) {
            userMetrics = res.responseDto.data.metric;

            let createUnitsofMeasurement = {};

            if (weight === 'weight') {
              if (this.state.weightUomSymbol === 'lbs') {
                createUnitsofMeasurement = {
                  metricDefaultUnitOfMeasure: {
                    metricId: userMetrics.id,
                    uomId: 17,
                  },
                };
              } else if (this.state.weightUomSymbol === 'g') {
                createUnitsofMeasurement = {
                  metricDefaultUnitOfMeasure: {
                    metricId: userMetrics.id,
                    uomId: '18',
                  },
                };
              } else {
                createUnitsofMeasurement = {
                  metricDefaultUnitOfMeasure: {
                    metricId: userMetrics.id,
                    uomId: '19',
                  },
                };
              }
            } else {
              if (this.state.heightUomSymbol === 'm') {
                createUnitsofMeasurement = {
                  metricDefaultUnitOfMeasure: {
                    metricId: userMetrics.id,
                    uomId: 1,
                  },
                };
              } else if (this.state.heightUomSymbol === 'cm') {
                createUnitsofMeasurement = {
                  metricDefaultUnitOfMeasure: {
                    metricId: userMetrics.id,
                    uomId: '2',
                  },
                };
              } else {
                createUnitsofMeasurement = {
                  metricDefaultUnitOfMeasure: {
                    metricId: userMetrics.id,
                    uomId: '3',
                  },
                };
              }
            }

            axiosCommon.axiosPUT(
              CREATE_UOM,
              res => {
                if (res.type === 'SUCCESS') {
                  if (res.responseDto.data.metricDefaultUnitOfMeasure) {
                    metricUOM = res.responseDto.data.metricDefaultUnitOfMeasure;
                    if (userMetrics && metricUOM) {
                      let getAllMetric = {};
                      if (this.props.unitsOfMeasure) {
                        let unit = this.props.unitsOfMeasure.filter(res => {
                          return res.id === this.state.selectedUnit;
                        });
                        if (unit && unit.length > 0) {
                          getAllMetric.selectMetricArray = userMetrics;
                          getAllMetric.selectUOMArray = unit[0];
                        }
                      }
                      this.props.createUserMetric(
                        userMetrics,
                        metricUOM,
                        () => {
                          this.setState({saveWait: false});
                        },
                      );
                    }
                  }
                } else {
                  if (res.errorDto === 'SESSION_TIME_OUT') {
                    console.log("SESSION_TIME_OUT, logging out; EditProfileScreen::updateMetric 1");
                    logout();
                  } else if (res.errorDto === 'NETWORK_ERROR') {
                    this.props.commonErrorHandler('Network Error');
                  } else if (res.errorDto !== 'undefined') {
                    this.props.commonErrorHandler(res.errorDto.data.error);
                    this.setState({
                      saveWait: false,
                    });
                  }
                }
              },
              createUnitsofMeasurement,
            );
          }
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("SESSION_TIME_OUT, logging out; EditProfileScreen::updateMetric 2");
            logout();
          } else if (res.errorDto !== 'undefined') {
            this.props.commonErrorHandler(res.errorDto.data.error);
            this.setState({
              saveWait: false,
            });
          }
        }
      },
      createMetric,
    );
  };

  onPressSave = () => {
    if (
      !this.state.emailValidate ||
      !this.state.validFirstName ||
      !this.state.validLastName
    ) {
      this.validate(this.props.conditions);
      this.nameOrEmail = true;
      this.validationCheckInPage();
      return;
    }

    if (this.validate(this.props.conditions)) {
      if (!this.checkConditionsExist() && !this.checkUnSavedConditionsExist()) {
        this.setState({saveWait: true});
        let dob = parseInt(
          moment.utc(this.state.dob, 'YYYY-MM-DD').format('x'),
        );
        
        if (this.state.height && this.state.height !== '') {
          if (!this.state.heightUomSymbol) {
            this.setState({heightUomSymbol: 'm'});
          }
        }

        if (this.state.weight && this.state.weight !== '') {
          if (!this.state.weightUomSymbol) {
            this.setState({weightUomSymbol: 'lbs'});
          }
        }

        let editProfile = {
          id: this.state.id,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
          ethnicity: this.state.ethnicity,
          city: this.state.city ? this.state.city : null,
          state: this.state.state,
          country: this.state.country ? this.state.country : null,
          lastEulaAgreement: null,
          birthday: dob ? dob : 0,
          sex: this.state.gender ? this.state.gender : null,
          validated: null,
          weight: this.state.weight,
          weightUomSymbol:
            this.state.weight &&
            this.state.weight !== '' &&
            !this.state.weightUomSymbol
              ? 'lbs'
              : this.state.weightUomSymbol,
          heightUomSymbol:
            this.state.height &&
            this.state.height !== '' &&
            !this.state.heightUomSymbol
              ? 'm'
              : this.state.heightUomSymbol,
          height: this.state.height,
        };

        if (
          this.state.email.toLowerCase() !== this.props.user.email.toLowerCase()
        ) {
          this.setState({emailIsChanged: true});
        }

        pendoTrackEvent('Profile@ > Save');
        this.props.updateUserConditions(
          this.props.conditions,
          () => {
            this.props.updateProfile(
              editProfile,
              async res => {
                if (this.state.height && this.state.height !== '') {
                  const hightMetrixUser = this.getHightMetrixUser();

                  if (hightMetrixUser && hightMetrixUser.length > 0) {
                    hightMetrixUser.filter(res => {
                      let uoMeasure = [];
                      let udmuom = this.props.userDefinedMetricUnitOfMeasures.filter(
                        function(userDefinedMetricUnitOfMeasures) {
                          return (
                            userDefinedMetricUnitOfMeasures.metricId === res.id
                          );
                        },
                      );
                      if (udmuom && udmuom.length > 0) {
                        uoMeasure = this.props.unitsOfMeasure.filter(function(
                          unit,
                        ) {
                          return unit.id === udmuom[0].uomId;
                        });
                      }
                      if (uoMeasure && uoMeasure.length > 0) {
                        if (
                          uoMeasure[0].symbol === this.state.weightUomSymbol
                        ) {
                        } else {
                          this.updateMetric('Height', 1);
                        }
                      }
                      return uoMeasure;
                    });
                  } else {
                    this.updateMetric('Height', 1);
                  }
                }
                if (this.state.weight && this.state.weight !== '') {
                  const weightMetrixUser = this.getWeightMetrixUser();

                  if (weightMetrixUser && weightMetrixUser.length > 0) {
                    weightMetrixUser.filter(res => {
                      let uoMeasure = [];
                      let udmuom = this.props.userDefinedMetricUnitOfMeasures.filter(
                        function(userDefinedMetricUnitOfMeasures) {
                          return (
                            userDefinedMetricUnitOfMeasures.metricId === res.id
                          );
                        },
                      );
                      if (udmuom && udmuom.length > 0) {
                        uoMeasure = this.props.unitsOfMeasure.filter(function(
                          unit,
                        ) {
                          return unit.id === udmuom[0].uomId;
                        });
                      }
                      if (uoMeasure && uoMeasure.length > 0) {
                        if (
                          uoMeasure[0].symbol === this.state.weightUomSymbol
                        ) {
                        } else {
                          this.updateMetric('Weight', 3, 'weight');
                        }
                      }
                      return uoMeasure;
                    });
                  } else {
                    this.updateMetric('Weight', 3, 'weight');
                  }
                }
                const waitingForDelete = this.state.conditionsToDelete;
                if (waitingForDelete.length > 0) {
                  waitingForDelete.forEach(async (res) => {
                    await this.props.onDeleteUseCondition(res, false, () => {});
                  });
                }
                const sAllergies = Object.assign([], this.state.newUserAllergies).filter(item => item.id < 0);
                const dAllergies = Object.assign([], this.state.userAllergiesTobeDeleted);
                if (sAllergies.length > 0) {
                  for (const res of sAllergies) {
                    this.props.onUpdateAllergy({...res, id: -1}, () => {}, this.props.journalUserId);
                  }
                }
                if (dAllergies.length > 0) {
                  for (const res of dAllergies) {
                    this.props.onDeleteAllergy(res, () => {});
                  }
                }
                
                if (res) {
                  if (Platform.OS !== WEB && this.state.emailIsChanged) {
                    await KeychainWrapper.resetGenericPassword();
                  }

                  setTimeout(() => {
                    this.setState({saveWait: false});
                    this.redirectProfile(true);
                  }, 500);
                  
                }
              },
              this.props.journalUserId,
            );
          },
          this.props.journalUserId,
        );
      } else {
        this.props.commonErrorHandler('Same condition exist multiple times.');
      }
    } else {
      this.validationCheckInPage();
    }
  };

  validationCheckInPage = () => {
    if (this.invalidMedicalConditions > 0) {
      let validConditions =
        this.props.conditions.length - this.invalidMedicalConditions;
      this.scrollUpScreen(
        0,
        validConditions * 100 + 135,
        0,
        validConditions * 100 + 135,
      );
    } else if (this.nameOrEmail) {
      this.scrollUpScreen(
        0,
        this.props.userAllergies.length * 30 +
          this.props.conditions.length * 130 +
          350,
        0,
        this.props.userAllergies.length * 30 +
          this.props.conditions.length * 130 +
          400,
      );
    } else if (this.state.day || this.state.month || this.state.year) {
      this.scrollUpScreen(
        0,
        this.props.userAllergies.length * 30 +
          this.props.conditions.length * 130 +
          450,
        0,
        this.props.userAllergies.length * 30 +
          this.props.conditions.length * 130 +
          500,
      );
    }
  };

  scrollUpScreen = (webX, webY, mobileX, mobileY) => {
    setTimeout(() => {
      Platform.OS === WEB
        ? this.scrollViewRef?.scrollTo && typeof this.scrollViewRef?.scrollTo === "function" && this.scrollViewRef?.scrollTo({ x: webX, y: webY, animated: true })
        : this.scrollViewRef?.scrollToPosition(mobileX, mobileY, true);
    }, 500);
  };

  checkConditionsExist = () => {
    let unSavedConditionFilterList = this.props.conditions.filter(
      res => res.id === -1,
    );
    let savedConditionFilterList = this.props.conditions.filter(
      res => res.id !== -1,
    );
    let savedFiltedItems = savedConditionFilterList.filter(elem =>
      unSavedConditionFilterList.find(
        ({name}) => elem.name.trim() === name.trim(),
      ),
    );
    if (savedFiltedItems.length !== 0) {
      return true;
    } else {
      return false;
    }
  };
  checkUnSavedConditionsExist = () => {
    let unSavedConditionFilterList = this.props.conditions.filter(
      res => res.id === -1,
    );
    let unique = [
      ...new Set(unSavedConditionFilterList.map(a => a.name.trim())),
    ];
    if (unique.length !== unSavedConditionFilterList.length) {
      return true;
    } else {
      return false;
    }
  };
  validate = conditions => {
    const {day, month, year} = this.state;
    let valid = true;
    let invlidIndex = {};

    conditions.map((res, index) => {
      if (res.name === null || res.name === '') {
        valid = false;
        res.validName = false;
        invlidIndex[index] = index;
      } else {
        res.validName = true;
      }
      return res;
    });

    this.invalidMedicalConditions = Object.keys(invlidIndex).length;

    if (!valid) {
      this.props.profileInitConditions(conditions, invlidIndex);
    }

    if (day) {
      valid = false;
      this.setState({invalidDay: true});
    }
    if (month) {
      valid = false;
      this.setState({invalidMonth: true});
    }
    if (year) {
      valid = false;
      this.setState({invalidYear: true});
    }

    return valid;
  };

  handleOnDatePress = date => {
    const newDate = date ? moment(date).format('YYYY-MM-DD') : null;
    this.setState({
      dob: newDate,
      showSetDate: newDate,
      visibleCalModal: false,
    });
  };

  checkInvalidDate = (day, month, year) => {
    if (
      (day !== '-1' && month !== '-1' && year !== '-1') ||
      (day === '-1' && month === '-1' && year === '-1')
    ) {
      this.setState({onlyYear: false});
    }
    if (day === '-1' && month === '-1' && year === '-1') {
      this.setState({
        day: false,
        month: false,
        year: false,
        invalidDay: false,
        invalidMonth: false,
        invalidYear: false,
      });
    } else {
      this.setState({
        day: day === '-1' ? true : false,
        month: month === '-1' ? true : false,
        year: year === '-1' ? true : false,
      });
    }
  };

  onPressCancel = () => {
    const {
      firstName,
      lastName,
      email,
      ethnicity,
      city,
      state,
      country,
      dob,
      gender,
      validated,
      weight,
      weightUomSymbol,
      height,
      heightUomSymbol,
      day,
      year,
      month,
      conditionsToDelete,
      newUserAllergies,
      userAllergiesTobeDeleted
    } = this.state;
    const {conditions, userAllergies} = this.props;
    let temp = [];
    if (conditions && conditions.length > 0) {
      conditions.forEach(res => {
        temp.push({name: res.name, year: res.year});
      });
    }

    let changeHash = hasValue(
      firstName +
        ',' +
        lastName +
        ',' +
        email +
        ',' +
        ethnicity +
        ',' +
        city +
        ',' +
        state +
        ',' +
        country +
        ',' +
        dob +
        ',' +
        gender +
        ',' +
        validated +
        ',' +
        weight +
        ',' +
        weightUomSymbol +
        ',' +
        height +
        ',' +
        heightUomSymbol +
        ',' +
        conditions,
    );

    if (this.hasValue === changeHash) {
      if (day || month || year) {
        this.setState({confirmBox: true});
      } else if (JSON.stringify(this.props.conditions) !== JSON.stringify(this.props.userConditions)) {
        this.setState({confirmBox: true});
      } else if (conditionsToDelete.length > 0) {
        this.setState({confirmBox: true});
      } else if (JSON.stringify(userAllergies) !== JSON.stringify(newUserAllergies) || userAllergiesTobeDeleted.length > 0) {
        this.setState({confirmBox: true});
      } else {
          this.redirectProfile();
      }
    } else {
      if(this.state.loading) {
          this.redirectProfile();
      }
      this.setState({confirmBox: true});
    }
  
  };

  redirectProfile = (status= false) => {
    if (!status) {
      if (JSON.stringify(this.props.conditions) !== JSON.stringify(this.state.initConditions)) {
        this.state.initConditions.forEach(item => this.props.setUserCondition(item))
      }
    }
    
    if(this.state.fromCommiunityProfile){
      const {navigation, route} = this.props;
      navigation.reset({
        index: 0,
        routes: [{ 
          name: PEER_TO_PEER.path,
          params: {from: route.name}
        }],
      }); 

    }else{
      this.props.navigation.reset({
        index: 0,
        routes: [{ name: PROFILE.path }],
      });
    }

    this.setState({newUserAllergies: [], userAllergiesTobeDeleted: []});
  }

  getHeightUnits = () => {
    let heightUnits = [];

    let getHeightUnits = this.props.unitsOfMeasureDigest.unitsOfMeasure.filter(
      res => {
        return res.typeOfMeasurement === 1;
      },
    );
    getHeightUnits.forEach((res, index) =>
      res.symbol !== 'km'
        ? heightUnits.push({
            label: res.symbol,
            value: res.symbol,
            key: index + '_' + res.symbol,
          })
        : null,
    );
    this.setState({heightUnits});
  };

  getWeightUnits = () => {
    let weightUnits = [];
    let getWightUnit = this.props.unitsOfMeasureDigest.unitsOfMeasure.filter(
      res => {
        return res.typeOfMeasurement === 3;
      },
    );
    getWightUnit.forEach((res, index) =>
      weightUnits.push({
        label: res.symbol,
        value: res.symbol,
        key: 'unit_' + index,
      }),
    );
    this.setState({weightUnits});
  };

  getProfileChange = () => {
    const {
      firstName,
      lastName,
      email,
      ethnicity,
      city,
      state,
      country,
      dob,
      gender,
      validated,
      weight,
      weightUomSymbol,
      height,
      heightUomSymbol,
      day,
      year,
      month,
    } = this.state;
    const {conditions, userAllergies} = this.props;
    let changeHash = hasValue(
      firstName +
        ',' +
        lastName +
        ',' +
        email +
        ',' +
        ethnicity +
        ',' +
        city +
        ',' +
        state +
        ',' +
        country +
        ',' +
        dob +
        ',' +
        gender +
        ',' +
        validated +
        ',' +
        weight +
        ',' +
        weightUomSymbol +
        ',' +
        height +
        ',' +
        heightUomSymbol +
        ',' +
        conditions,
    );
    let flag = null;

    if (this.hasValue === changeHash) {
      if (day || month || year) {
        flag = true;
      } else if (JSON.stringify(this.props.conditions) !== JSON.stringify(this.props.userConditions)) {
        flag = true;
      } else if (JSON.stringify(userAllergies) !== JSON.stringify(this.state.newUserAllergies) || this.state.userAllergiesTobeDeleted.length > 0) {
        flag = true;
      } else {
        flag = false;

      }
    } else {
      flag = true;
    }
    return flag;
  };


  setConditionsToDelete = (condition) => {
    if (condition) {
      this.setState({conditionsToDelete: [...this.state.conditionsToDelete, condition]});
    } else {
      setTimeout(() => {
        this.forceUpdate();
      }, 0);
    }
  }

  render() {
    const {
      invalidDay,
      invalidMonth,
      invalidYear,
      weightUnits,
      heightUnits,
      conditionsToDelete,
    } = this.state;
    return (
      <React.Fragment>
        <EditProfile
          newUserAllergies={this.state.newUserAllergies}
          addUserAllergy={this.addUserAllergy}
          deleteUserAllergy={this.deleteUserAllergy}
          setConditionsToDelete={this.setConditionsToDelete}
          initConditions={this.state.initConditions}
          onPressSave={this.onPressSave}
          getProfileChange={this.getProfileChange() || conditionsToDelete.length > 0}
          onPressDate={() => {
            this.setState({
              visibleCalModal: true,
            });
          }}
          onRequestClose={() => {
            this.setState({
              visibleCalModal: false,
            });
          }}
          onBackdropPress={() =>
            this.setState({
              visibleCalModal: false,
            })
          }
          getWeightUnits={weightUnits}
          getHeightUnits={heightUnits}
          visibleCalModal={this.state.visibleCalModal}
          dob={this.state.dob}
          handleOnDatePress={this.handleOnDatePress}
          onChangeGender={type => {
            this.setState({
              gender: type,
            });
          }}
          onPressCancel={this.onPressCancel}
          gender={this.state.gender}
          height={this.state.height}
          onChangeTextHeight={height => {
            var valid = height.match(/^\d*\.?\d*$/);
            if (isNaN(height) || !valid) {
              if (this.state.height === undefined) {
                this.setState({height: ''});
              }
            } else {
              this.setState({height: height});
            }
          }}
          heightUomSymbol={this.state.heightUomSymbol}
          onChangeHeightUomSymbol={heightUomSymbol => {
            this.setState({heightUomSymbol: heightUomSymbol});
          }}
          firstName={this.state.firstName}
          onChangeTextFirstName={value => {
            this.setState({firstName: value});
            if (value && value.trim() !== '') {
              this.setState({validFirstName: true});
            } else {
              this.setState({validFirstName: false});
            }
          }}
          validFirstName={this.state.validFirstName}
          lastName={this.state.lastName}
          onChangeTextLastName={value => {
            this.setState({lastName: value});
            if (value && value.trim() !== '') {
              this.setState({validLastName: true});
            } else {
              this.setState({validLastName: false});
            }
          }}
          validLastName={this.state.validLastName}
          email={this.state.email}
          onChangeTextEmail={email => {
            if (email && email.trim() !== '') {
              this.setState({
                emailValidate: validator.validateEmail(email),
                email: email,
              });
            } else {
              this.setState({
                emailValidate: false,
                email: '',
              });
            }
          }}
          checkInvalidDate={this.checkInvalidDate}
          emailValidate={this.state.emailValidate}
          weight={this.state.weight}
          onChangeTextWeight={weight => {
            var valid = weight.match(/^\d*\.?\d*$/);
            if (isNaN(weight) || !valid) {
              if (this.state.weight === undefined) {
                this.setState({weight: ''});
              }
            } else {
              this.setState({weight: weight});
            }
          }}
          weightUomSymbol={this.state.weightUomSymbol}
          onChangeWeightUomSymbol={weightUomSymbol => {
            this.setState({weightUomSymbol: weightUomSymbol});
          }}
          emergencyContact={this.state.emergencyContact}
          ethnicity={this.state.ethnicity}
          city={this.state.city}
          onChangeTextEthnicity={ethnicity => {
            this.setState({ethnicity: ethnicity});
          }}
          onChangeTextCity={city => {
            this.setState({city: city});
          }}
          maxDate={new Date()}
          userId={this.props.user.id}
          state={this.state.state}
          country={this.state.country}
          onChangeState={state => {
            this.setState({state: state});
          }}
          onChangeCountry={country => {
            if (
              country !== 'Canada' ||
              country !== 'United States of America (USA)'
            ) {
              this.setState({state: ''});
            }
            this.setState({country: country});
          }}
          saveWait={this.state.saveWait}
          invalidYear={invalidYear}
          invalidMonth={invalidMonth}
          invalidDay={invalidDay}
          handleOnPressBackButton={() => {
            this.onPressCancel();
          }}
          journalUserId={this.props.journalUserId}
          loading={this.state.loading}
          hasNotch={this.props.hasNotch}
          index={PROFILE.index}
          navigation={this.props.navigation}
          route={this.props.route}
          scrollViewRef={val => {
            this.scrollViewRef = val;
          }}
        />
        {this.state.confirmBox &&
        <SavingConfirmation
          visibleModal={this.state.confirmBox}
          onBackdropPress={() => {
            this.setState({confirmBox: false});
          }}
          onRequestClose={() => {
            this.setState({confirmBox: false});
          }}
          alertBody={alertBody}
          alertTitle={profileTitle}
          handleCancel={() => this.redirectProfile()} //this.props.navigation.goBack()
          handleConfirm={() => {
            this.setState({confirmBox: false});
            this.onPressSave();
          }}
          confirmWait={this.state.saveWait}
          disabled={this.state.saveWait}
        />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.routines.user,
    profile: state.routines.peer2peer,
    unitsOfMeasureDigest: state.routines.unitsOfMeasureDigest,
    unitsOfMeasureDigestProcessed: state.routines.unitsOfMeasureDigestProcessed,
    metricsDigest: state.routines.metricsDigest,
    conditions: state.profile.conditions,
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    journalUserId: state.uistate.journalUserId,
    hasNotch: state.uistate.hasNotch,
    userDefinedMetricUnitOfMeasures:
      state.routines.metricsDigest.userDefinedMetricUnitOfMeasures,
    unitsOfMeasure: state.routines.unitsOfMeasureDigest.unitsOfMeasure,
    userAllergies: state.routines.userAllergies,
    userConditions: state.routines.userConditions,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateProfile: (editProfile, callBack, journalUserId) =>
      dispatch(actions.updateProfile(editProfile, callBack, journalUserId)),
    updateUserConditions: (conditions, callBack, journalUserId) =>
      dispatch(
        actions.updateUserConditions(conditions, callBack, journalUserId),
      ),
    updatePeer2PeerProfile: (p2pProfile, callBack, journalUserId) =>
      dispatch(
        actions.updatePeer2PeerProfile(p2pProfile, callBack, journalUserId),
      ),
    profileInitConditions: (conditions, conditionValidation) =>
      dispatch(actions.profileInitConditions(conditions, conditionValidation)),
    commonErrorHandler: errorDescription =>
      dispatch(actions.commonErrorHandler(errorDescription)),
    createUserMetric: (userMetrics, metricUOM, callback) =>
      dispatch(actions.createUserMetric(userMetrics, metricUOM, callback)),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
    onDeleteUseCondition: (id, peer, callBack) =>
      dispatch(actions.deleteUseCondition(id, peer, callBack)),
    setUserCondition: condition =>
      dispatch(actions.updateUserConditionsSuccess(condition)),
    onDeleteAllergy: (id, callBack) =>
      dispatch(actions.deleteUserAllergy(id, callBack)),
    onUpdateAllergy: (allergy, callBack, journalUserId) =>
      dispatch(actions.updateUserAllergy(allergy, callBack, journalUserId)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditProfileScreen);
