import React from 'react';
import { View, Text, TextInput, Image,Platform } from 'react-native';
import styles from '../../../../Css';
import stylesResponsive from "../../../../CssResponsive";
import { ORGANIZATION_NAME } from '../../../../util/commonUiConfig';
import {WEB} from '../../../../util/platformWindowConfig';

const DEFAULT_HEIGHT_OF_TEXTAREA = 128;
const ProfileTextArea = (props) => {
    const { styles: stylesRes, ids } = stylesResponsive.getProcessedStyles();
    
    const[focused, setFocused]= React.useState(false);
    const[textHeight, setHeight]= React.useState(128);

    const { story, disableStory, onBlurStory,onChangeStory,storyDisableBorder  } = props;
    
    return (
        <View style={[styles.inputfeildWidth, styles.userNameContainer, stylesRes.userNameRes]} dataSet={{ media: ids.userNameRes }}>
            <View style={[
                styles.profileUsernameInput, 
                styles.textAreaInput, 
                { height : textHeight + 12 },
                storyDisableBorder ? styles.inValidColor : focused && !disableStory? styles.blueBorderProfile : styles.validBorderProfile, 
                ]} >
                <View style={styles.inputfeildWidth}>
                    <TextInput
                        editable={!disableStory}
                        allowFontScaling={false}
                        multiline={true}
                        
                        onChangeText={onChangeStory}
                        value={story}
                        onBlur={() => {
                            setFocused(false);
                            onBlurStory();
                        }}
                        placeholder={`What would you like others to know about you, and what brings you to ${ORGANIZATION_NAME}?`}
                        nativeID={'communityProfileShareStory'}
                        style={{
                            ...Platform.select({
                              android: {
                                textAlignVertical: "top"
                              },
                              
                            }),
                            overflow: 'visible',
                            textAlignVertical: 'top',
                            minHeight: textHeight,
                        }}
                        onFocus={()=>setFocused(true)}
                        onContentSizeChange={(event) => {
                           if(event?.nativeEvent?.contentSize?.height > DEFAULT_HEIGHT_OF_TEXTAREA && Platform.OS !== WEB ) setHeight(event.nativeEvent.contentSize.height)
                        }}

                    />
                </View>
            </View>

            

                <View style={styles.flexRowAiCt}>
                {storyDisableBorder && (
                    <Image

                        style={styles.availableIconProfile}
                        source={require('../../../../assets/icons/unavailble.png')}
                    />
                )}

                    <Text
                        style={[

                            styles.inputUnderTextTwo,
                            stylesRes.mobileTextThree
                        ]}
                        allowFontScaling={false}
                        dataSet={{ media: ids.mobileTextThree }}
                    > Max 500 characters
                    </Text>
                </View>
            
        </View>
    );
};



export default ProfileTextArea;
